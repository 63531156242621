import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { ApiService } from "src/app/other/services/api.service";
import { Constants } from "src/app/other/constants";
import { UploadFileService } from "src/app/other/services/upload-file.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-update-individual-detail",
  templateUrl: "./update-individual-detail.component.html",
  styleUrls: ["./update-individual-detail.component.scss"],
})
export class UpdateIndividualDetailComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private uploadService: UploadFileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    uploadService.uploadStatus.subscribe((value) => {
      if (!!value["loaded"]) {
        this.selectedImages[value["objName"]].progPer =
          (value["loaded"] / value["total"]) * 100;
      }
    });
  }
  item: any = {};
  i: any = {};
  tForm: FormGroup;
  selectedLang: string = "english";
  currentUrl = "";
  labels = [
    {
      english: "new",
      hindi: "नया",
    },
    {
      english: "viral",
      hindi: "वायरल",
    },
    {
      english: "trending",
      hindi: "चर्चा में",
    },
  ];
  saveApiCalling: boolean = false;

  ratings = [
    {
      english: "U",
      hindi: "U",
    },
    {
      english: "U/A 7+",
      hindi: "U/A 7+",
    },
    {
      english: "U/A 13+",
      hindi: "U/A 13+",
    },
    {
      english: "U/A 16+",
      hindi: "U/A 16+",
    },
    {
      english: "A",
      hindi: "A",
    },
  ];

  listLanguage =
    this.getCookie("GlobalDialect") != null
      ? this.getCookie("GlobalDialect")
      : "har";

  videoList = {
    all: {
      collectionVideo: [],
      peripheralVideo: [],
    },
    selected: {
      collectionVideo: [],
      peripheralVideo: [],
    },
    s3Url: {},
  };

  s3Url = {};
  s3UrlIndividual = {};
  clips = {};

  individualVideoList = [];
  individualArtistList = {
    english: [],
    hindi: [],
  };
  imageGradientColorEng = [];
  imageGradientColorHin = [];
  storedData = {
    english: {
      thumbnail: {
        horizontal: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
          ratio2: {
            sourceLink: null,
            gradient: null,
          },
          ratio3: {
            sourceLink: "",
            gradient: "",
          },
          ratio4: {
            sourceLink: null,
            gradient: null,
          },
        },
        vertical: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
        square: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
      },
      activity: {
        action: "created",
        writerName: "Kunal",
        updatedAt: "",
      },
    },
    hindi: {
      thumbnail: {
        horizontal: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
          ratio2: {
            sourceLink: null,
            gradient: null,
          },
          ratio3: {
            sourceLink: "",
            gradient: "",
          },
          ratio4: {
            sourceLink: null,
            gradient: null,
          },
        },
        vertical: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
        square: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
      },
      activity: {
        action: "created",
        writerName: "Maverick",
        updatedAt: "",
      },
    },
  };

  formType: string = "Create";
  formStatus = {
    english: false,
    hindi: false,
  };

  storeDetail = {
    slug: null,
    english: {
      _id: 0,
    },
    hindi: {
      _id: 0,
    },
  };

  selecteVideoElement = {
    title: null,
    sourceLink: null,
    hlsSourceLink: null,
    duration: null,
    thumbnail: null,
    id: null,
    clips: null,
    subtitle: null,
  };

  allGenreList = {
    showGenre: false,
    allListData: [],
    selectedData: [],
    formData: {},
  };

  discardObj = {
    type: "",
    text: "",
    isShown: false,
  };

  categoryList = [];
  complianceList = [];

  selectedImages = {
    horizontal_large: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 16,
      heightRatio: 9,
    },
    square: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 1,
      heightRatio: 1,
    },
    // "vertical": {
    //   file: null,
    //   progPer: null,
    //   loader: false,
    //   imgURL: null,
    //   status: 'empty',
    //   sourceLink: null,
    //   widthRatio: 9,
    //   heightRatio: 16
    // },
    // "horizontal_small": {
    //   file: null,
    //   progPer: null,
    //   loader: false,
    //   imgURL: null,
    //   status: 'empty',
    //   sourceLink: null,
    //   widthRatio: 3,
    //   heightRatio: 2
    // }
    tv_image: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 1754,
      heightRatio: 353,
    },
    horizontal7_2: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 7,
      heightRatio: 2,
    },
    vertical: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 2,
      heightRatio: 3,
    },
  };

  markTouched = {
    english: false,
    hindi: false,
  };

  userData = {
    _id: "",
    email: "",
    roleId: 0,
    firstName: "",
    lastName: "",
    lastLogin: "",
  };
  apidataLoaded = false;
  viewMode = false;
  redirectFromComingSoon = false;

  otherImageObj = {
    horizontal_large: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 16,
      heightRatio: 9,
    },
    square: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 1,
      heightRatio: 1,
    },
    tv_image: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 1754,
      heightRatio: 353,
    },
    horizontal7_2: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 7,
      heightRatio: 2,
    },
    vertical: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 2,
      heightRatio: 3,
    },
    // "vertical": {
    //   file: null,
    //   progPer: null,
    //   loader: false,
    //   imgURL: null,
    //   status: 'empty',
    //   sourceLink: null,
    //   widthRatio: 9,
    //   heightRatio: 16
    // },
    // "horizontal_small": {
    //   file: null,
    //   progPer: null,
    //   loader: false,
    //   imgURL: null,
    //   status: 'empty',
    //   sourceLink: null,
    //   widthRatio: 3,
    //   heightRatio: 2
    // },
    imageGradientColorEng: [],
    imageGradientColorHin: [],
    english: {
      gradient: "",
    },
    hindi: {
      gradient: "",
    },
    lang: "",
    defaultImageStatus: false,
    color: "",
  };
  otherImages = { english: [], hindi: [] };

  warningType = [
    {
      "id": 0,
      "enText": "",
      "hinText":"",
      "type" : "Select"
    },
    {
      "id": 1,
      "enText": "Alcohol is injurious to health.",
      "hinText":"शराब स्वास्थ्य के लिए हानिकारक है",
      "type" : "Alcohol"
    },
    {
      "id": 2,
      "enText": "Smoking is injurious to health.",
      "hinText":"धूम्रपान स्वास्थ्य के लिए हानिकारक है",
      "type" : "Smoking"
    },
    {
      "id": 3,
      "enText": "Tobacco consumption is injurious to health.",
      "hinText":"तम्बाकू का सेवन स्वास्थ्य के लिए हानिकारक है।",
      "type" : "Tobacco"
    }
  ];

  ngOnInit() {
    this.tForm = new FormGroup({
      english: new FormGroup({
        title: new FormControl("", [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ]),
        slug: new FormControl("", [Validators.required]),
        description: new FormControl("", [
          Validators.required,
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        language: new FormControl("", [Validators.required]),
        artistList: new FormControl([], [Validators.required]),
        label: new FormControl("", [Validators.required]),
        categoryList: new FormArray([]),
        complianceList: new FormArray([], [Validators.required]),
        complianceRating: new FormControl("", [Validators.required]),
        tags: new FormControl([], [Validators.required]),
        gradient: new FormControl("#fffff", [Validators.required]),
        // 'gradient': new FormControl('#ffffff'),
        metaTitle: new FormControl("", [Validators.maxLength(50)]),
        metaKeyword: new FormControl([]),
        metaDescription: new FormControl("", [
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        preContentWarningText: new FormControl("", [
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        horizontal_large: new FormControl(""),
        square: new FormControl(""),
        // 'vertical': new FormControl(''),
        // 'horizontal_small': new FormControl(''),

        freeEpisode: new FormControl(false),
        freeEpisodeDuration: new FormControl(600),
        tv_image: new FormControl(""),
        horizontal7_2: new FormControl(""),
        vertical: new FormControl(""),
        gradients: new FormControl(""),
        randomOrder: new FormControl(0),

        isIntro         : new FormControl(),
        isNextEpisode   : new FormControl(),
        isWarningMess   : new FormControl(),
        isAds           : new FormControl(),
        introStartTime  : new FormControl(),
        introEndTime    : new FormControl(),
        nextEpisodeNudgeStartTime  : new FormControl(),
        //nextEpisodeNudgeEndTime  : new FormControl(),
        contentWarnings  : new FormControl([]),
        adsTime           : new FormControl([]),
      }),
      hindi: new FormGroup({
        title: new FormControl("", [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ]),
        slug: new FormControl("", [Validators.required]),
        description: new FormControl("", [
          Validators.required,
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        language: new FormControl("", [Validators.required]),
        artistList: new FormControl([], [Validators.required]),
        label: new FormControl("", [Validators.required]),
        categoryList: new FormArray([]),
        complianceList: new FormArray([], [Validators.required]),
        complianceRating: new FormControl("", [Validators.required]),
        tags: new FormControl([], [Validators.required]),
        gradient: new FormControl("#fffff", [Validators.required]),
        // 'gradient': new FormControl('#ffffff'),
        metaTitle: new FormControl("", [Validators.maxLength(50)]),
        metaKeyword: new FormControl([]),
        metaDescription: new FormControl("", [
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        preContentWarningText: new FormControl("", [
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        horizontal_large: new FormControl(""),
        square: new FormControl(""),
        // 'vertical': new FormControl(''),
        // 'horizontal_small': new FormControl(''),
        tv_image: new FormControl(""),
        horizontal7_2: new FormControl(""),
        vertical: new FormControl(""),
        gradients: new FormControl(""),
        randomOrder: new FormControl(0),
        freeEpisode: new FormControl(false),
        freeEpisodeDuration: new FormControl(600),
        
        isIntro         : new FormControl(),
        isNextEpisode   : new FormControl(),
        isWarningMess   : new FormControl(),
        isAds           : new FormControl(),
        introStartTime  : new FormControl(),
        introEndTime    : new FormControl(),
        nextEpisodeNudgeStartTime  : new FormControl(),
        //nextEpisodeNudgeEndTime  : new FormControl(),
        contentWarnings  : new FormControl([]),
        adsTime           : new FormControl([]),
      }),
    });

    // subscribtion for form validation
    this.tForm.valueChanges.subscribe((result) => {
      this.validateForm();
    });

    this.route.params.subscribe((params) => {
      if (!!params["slug"]) {
        this.formType = "edit";
        this.storeDetail.slug = params["slug"];
      }
    });

    // API call for category and genre sub-genre listing
    this.getCategoryList();

    this.getComplianceCategories();

    this.getGenreList();
    this.getIndividualVideoList();
    this.getArtistList();

    var retrievedObject = localStorage.getItem("userdata");
    this.userData = JSON.parse(retrievedObject);

    if (
      this.route["_routerState"].snapshot.url.match("dashboard/review-publish")
    ) {
      this.currentUrl = "review-publish";
    } else if (
      this.route["_routerState"].snapshot.url.match("dashboard/content")
    ) {
      this.currentUrl = "content";
    } else if (
      this.route["_routerState"].snapshot.url.match("dashboard/publish")
    ) {
      this.currentUrl = "publish";
    }

    if (this.route["_routerState"].snapshot.url.match("view/")) {
      this.viewMode = true;
    }

    /* Auto Select Header Language */
    this.tForm.patchValue({
      hindi: {
        ["language"]: this.listLanguage,
      },
    });
    this.tForm.patchValue({
      english: {
        ["language"]: this.listLanguage,
      },
    });
    if (this.route["_routerState"].snapshot.url.match("fromCS/")) {
      this.redirectFromComingSoon = true;
    }
  }

  // Custom validtor to avoid empty spaces.
  public noWhitespaceValidator(control: FormControl) {
    let isWhitespace =
      !(
        control.value === null ||
        control.value === "" ||
        control.value === undefined
      ) && control.value.trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  // copy artist language in both the arrays
  copyDataFromOneLangToOther(fieldName) {
    let value = this.tForm.get(this.selectedLang + "." + fieldName).value;

    if (this.selectedLang == "english")
      this.tForm.patchValue({
        hindi: {
          [fieldName]: value,
        },
      });
    else
      this.tForm.patchValue({
        english: {
          [fieldName]: value,
        },
      });
  }

  // copy category data from one language to another
  copyCategoryDataToAnother(fieldName) {
    let enValue = this.tForm.get("english.categoryList").value;
    let hinValue = this.tForm.get("hindi.categoryList").value;
    hinValue.forEach((element, index) => {
      if (this.selectedLang == "english")
        hinValue[index].checked = enValue[index].checked;
      else enValue[index].checked = hinValue[index].checked;
    });

    if (this.selectedLang == "english") {
      this.tForm.patchValue({
        hindi: {
          [fieldName]: hinValue,
        },
      });
    } else {
      this.tForm.patchValue({
        english: {
          [fieldName]: enValue,
        },
      });
    }

    // //console.log(this.tForm.get('english.categoryList').value);
    // //console.log(this.tForm.get('hindi.categoryList').value);
  }

  copyComplienceCategoryDataToAnother(fieldName) {
    let enValue = this.tForm.get("english.complianceList").value;
    let hinValue = this.tForm.get("hindi.complianceList").value;
    hinValue.forEach((element, index) => {
      if (this.selectedLang == "english")
        hinValue[index].checked = enValue[index].checked;
      else enValue[index].checked = hinValue[index].checked;
    });

    if (this.selectedLang == "english") {
      this.tForm.patchValue({
        hindi: {
          [fieldName]: hinValue,
        },
      });
    } else {
      this.tForm.patchValue({
        english: {
          [fieldName]: enValue,
        },
      });
    }
  }

  // change image function and api call
  changeImage(event, objName) {
    if (event.target.files.length === 0) return;

    let file = event.target.files[0];

    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      var self = this;
      this.checkImageDimension(
        _event,
        self,
        this.selectedImages[objName].widthRatio,
        this.selectedImages[objName].heightRatio,
        function (res) {
          if (res) {
            self.selectedImages[objName].imgURL = reader.result;
            self.selectedImages[objName].loader = true;
            self.selectedImages[objName].status = "uploading";
            let imageFileName =
              "episodeImage-" + Date.now() + "." + file.name.split(".")[1];
            self.uploadService
              .uploadSingleFile(file, imageFileName, "episode", objName)
              .then(
                (response) => {
                  if (response) {
                    // API call for uploading/change image
                    self.apiService
                      .getApiData(
                        Constants.url.uploadImage +
                          "?type=episode&format=" +
                          objName +
                          "&imageName=" +
                          imageFileName
                      )
                      .subscribe((response) => {
                        if (response["status"] == 200) {
                          self.selectedImages[objName].fileName = imageFileName;
                          self.selectedImages[objName].sourceLink =
                            imageFileName;
                          self.selectedImages[objName].status = "success";
                          if (
                            self.selectedImages[objName].widthRatio === 1 &&
                            self.selectedImages[objName].heightRatio === 1 &&
                            self.selectedLang == "english"
                          ) {
                            self.imageGradientColorEng = [];
                            self.imageGradientColorEng =
                              response["data"]["gradientColor"];

                            if (self.imageGradientColorEng.length > 0) {
                              self.setImageColor(self.imageGradientColorEng[0]);
                            }
                          }

                          if (
                            self.selectedImages[objName].widthRatio === 1 &&
                            self.selectedImages[objName].heightRatio === 1 &&
                            self.selectedLang == "hindi"
                          ) {
                            self.imageGradientColorHin = [];
                            self.imageGradientColorHin =
                              response["data"]["gradientColor"];

                            if (self.imageGradientColorHin.length > 0) {
                              self.setImageColor(self.imageGradientColorHin[0]);
                            }
                          }
                          self.selectedImages[objName].loader = false;
                          self.validateForm();
                        } else self.selectedImages[objName].status = "failed";
                      });
                  } else {
                    self.selectedImages[objName].status = "failed";
                  }
                  self.validateForm();
                },
                (error) => {
                  self.selectedImages[objName].status = "failed";
                }
              );
          } else {
            alert(
              "Image aspect ratio should be " +
                self.selectedImages[objName].widthRatio +
                ":" +
                self.selectedImages[objName].heightRatio
            );
          }
          self.validateForm();
        }
      );
    };
  }

  checkImageDimension(e, classObj, widthRatio, heightRatio, callback) {
    var isValid = false;
    var image = new Image();
    image.src = e.target["result"];
    let self = this;
    image.onload = function (): any {
      if ((image.width * heightRatio) / widthRatio === image.height) {
        isValid = true;
        callback(isValid);
      } else {
        callback(isValid);
      }
    };
  }

  checkSlugApi() {
    let reqSlug = "";
    if (!this.storeDetail.english._id) {
      if (this.tForm.get("english.title").valid) {
        reqSlug = this.convertToSlug(this.tForm.get("english.title").value);
        this.apiService
          .getApiData(Constants.url.checkSlug + "?type=episode&slug=" + reqSlug)
          .subscribe((response) => {
            if (response["status"] == 200) {
              if (!response["data"]["exists"]) {
                this.storeDetail.slug = reqSlug;

                this.tForm.patchValue({
                  english: {
                    slug: reqSlug,
                  },
                });
                this.tForm.patchValue({
                  hindi: {
                    slug: reqSlug,
                  },
                });
              } else {
                this.toastr.info("Slug already exists.Please try different");
                this.storeDetail.slug = "";

                this.tForm.patchValue({
                  english: {
                    slug: "",
                  },
                });
                this.tForm.patchValue({
                  hindi: {
                    slug: "",
                  },
                });
              }

              this.tForm.get("english.slug").markAllAsTouched();
              this.tForm.get("hindi.slug").markAllAsTouched();
            }
          });
      }
    }
  }

  // slug convertor
  convertToSlug(text) {
    return text.toLowerCase().replace(/\s\s+/g, " ").replace(/ +/g, "-");
  }

  setImageColor(color) {
    if (this.selectedLang == "english") {
      this.tForm.patchValue({
        english: {
          ["gradient"]: color,
        },
      });
    } else {
      this.tForm.patchValue({
        hindi: {
          ["gradient"]: color,
        },
      });
    }
  }

  // get genre list
  getGenreList() {
    this.apiService
      .getApiData(
        Constants.url.getGenreSubGenreList + "?language=" + this.listLanguage
      )
      .subscribe((response) => {
        if (response["status"] == 200) {
          this.allGenreList.allListData = response["data"]["genreSubGenre"];
          // call if slug is available
          this.viewIndividualDetail(this.storeDetail.slug);
        }
      });
  }

  // public requestAutocompleteItems = (text: string): Observable<Response> => {
  //   return this.apiService.getApiData(Constants.url.getIndividualArtistListing).pipe(map(res => res['data']['artistData']));
  // }

  // get Artist Video list
  getArtistList() {
    this.apiService
      .getApiData(Constants.url.getIndividualArtistListing)
      .subscribe((response) => {
        if (response["status"] == 200) {
          // this.individualArtistList=response['data']['artistData'];
          response["data"]["artistData"].map((item) => {
            if (item.displayLanguage == "en") {
              return this.individualArtistList["english"].push({
                id: item._id,
                firstName: item.firstName,
                lastName: item.lastName,
                callingName: item.callingName,
                name: item.firstName + " " + item.lastName,
                order: 0,
                city: item.city,
                profilePic: item.profilePic,
                slug: item.slug,
                status: item.status,
                gender: item.gender,
              });
            } else {
              return this.individualArtistList["hindi"].push({
                id: item._id,
                firstName: item.firstName,
                lastName: item.lastName,
                callingName: item.callingName,
                name: item.firstName + " " + item.lastName,
                order: 0,
                city: item.city,
                profilePic: item.profilePic,
                slug: item.slug,
                status: item.status,
                gender: item.gender,
              });
            }
          });
        }
      });
  }

  // add artist to hindi lang
  addArtistToOther(tag: any) {
    let artistHinIndex = this.individualArtistList["hindi"].findIndex(
      (x) => x.slug == tag.slug
    );
    if (artistHinIndex > -1) {
      let value = this.tForm.get("hindi.artistList").value;
      value.push(this.individualArtistList["hindi"][artistHinIndex]);

      this.tForm.patchValue({
        hindi: {
          artistList: value,
        },
      });
    }
  }

  // remove artist to other lang
  removeArtistToOther(tag: any) {
    let otherLang = this.selectedLang == "english" ? "hindi" : "english";

    let value = this.tForm.get(otherLang + ".artistList").value;
    let valueIndex = value.findIndex((x) => x.slug == tag.slug);
    value.splice(valueIndex, 1);

    this.tForm.patchValue({
      [otherLang]: {
        artistList: value,
      },
    });
  }

  // remove artist to other lang
  // removeArtistToOther(tag:any, formIndex){
  //   let otherLang = this.selectedLang == this.selectedLang?'hindi':this.selectedLang;

  //   let value = this.tForm.get('hindi')['controls'][formIndex]['controls']['artistList'].value;
  //   let valueIndex = value.findIndex(x => x.slug == tag.slug);
  //   value.splice(valueIndex, 1);

  //   this.tForm.get(otherLang)['controls'][formIndex].patchValue({
  //     'artistList': value
  //   });
  // }

  // get Artist Video list
  getIndividualVideoList() {
    if (this.individualVideoList.length <= 0) {
      this.apiService
        .getApiData(
          Constants.url.getIndividualVideoList +
            "?language=" +
            this.listLanguage
        )
        .subscribe((response) => {
          if (response["status"] == 200) {
            this.individualVideoList = response["data"]["episodeVideo"];
            this.s3UrlIndividual = response["data"]["s3Url"];
            this.s3Url = response["data"]["s3Url"];
          }
        });
    }
  }

  // bind/unbind the artis Story
  bindUnbindArtistStory(item) {
    //  //console.log(item);

    if (!item) {
      this.selecteVideoElement = {
        title: null,
        sourceLink: null,
        hlsSourceLink: null,
        duration: null,
        thumbnail: null,
        id: null,
        clips: null,
        subtitle: null,
      };
      // this.storedData.english.mediaList = [];
      // this.storedData.hindi.mediaList  = [];
    } else {
      item.id = Math.floor(Math.random() * 100) + 1;
      this.selecteVideoElement = item;
      // this.storedData.english.mediaList.push(item);
      // this.storedData.hindi.mediaList.push(item);
    }

    this.validateForm();
  }

  // get category list
  getCategoryList() {
    this.apiService
      .getApiData(Constants.url.getCategoryList)
      .subscribe((response) => {
        if (response["status"] == 200) {
          let newData = [];
          let categorySlugs = [];
          for (let element of response["data"]["categories"]) {
            var indexVal = categorySlugs.indexOf(element.slug);
            if (indexVal == -1) {
              let obj = {};
              obj["slug"] = element.slug;
              if (element.language === "en") {
                obj["english"] = { id: element._id, name: element.name };
              } else {
                obj["hindi"] = { id: element._id, name: element.name };
              }
              newData.push(obj);
              categorySlugs.push(element.slug);
            } else {
              if (element.language === "en") {
                newData[indexVal]["english"] = {
                  id: element._id,
                  name: element.name,
                };
              } else {
                newData[indexVal]["hindi"] = {
                  id: element._id,
                  name: element.name,
                };
              }
            }
          }

          newData.forEach((o, i) => {
            let formArray = this.tForm.get(
              "english" + ".categoryList"
            ) as FormArray;
            let hinFormArray = this.tForm.get(
              "hindi" + ".categoryList"
            ) as FormArray;
            formArray.push(
              this.fb.group({
                checked: new FormControl(null),
                slug: o.slug,
                id: o["english"].id,
                name: o["english"].name,
              })
            );
            hinFormArray.push(
              this.fb.group({
                checked: new FormControl(null),
                slug: o.slug,
                id: o["hindi"].id,
                name: o["hindi"].name,
              })
            );
          });
          this.categoryList = newData;
        }
      });
  }

  getComplianceCategories() {
    this.apiService
      .getApiData(Constants.url.getComplianceCategories)
      .subscribe((response) => {
        if (response["status"] == 200) {
          let newData = [];
          let categorySlugs = [];
          for (let element of response["data"]["categories"]) {
            var indexVal = categorySlugs.indexOf(element.slug);
            if (indexVal == -1) {
              let obj = {};
              obj["slug"] = element.slug;
              if (element.language === "en") {
                obj["english"] = { id: element._id, name: element.name };
              } else {
                obj["hindi"] = { id: element._id, name: element.name };
              }
              newData.push(obj);
              categorySlugs.push(element.slug);
            } else {
              if (element.language === "en") {
                newData[indexVal]["english"] = {
                  id: element._id,
                  name: element.name,
                };
              } else {
                newData[indexVal]["hindi"] = {
                  id: element._id,
                  name: element.name,
                };
              }
            }
          }

          newData.forEach((o, i) => {
            let formArray = this.tForm.get(
              "english" + ".complianceList"
            ) as FormArray;
            let hinFormArray = this.tForm.get(
              "hindi" + ".complianceList"
            ) as FormArray;
            formArray.push(
              this.fb.group({
                checked: new FormControl(null),
                slug: o.slug,
                id: o["english"].id,
                name: o["english"].name,
              })
            );
            hinFormArray.push(
              this.fb.group({
                checked: new FormControl(null),
                slug: o.slug,
                id: o["hindi"].id,
                name: o["hindi"].name,
              })
            );
          });
          this.complianceList = newData;
        }
      });
  }

  // view collection detail
  viewIndividualDetail(slug) {
    //console.log('aao----------------');

    if (!!slug) {
      this.apidataLoaded = true;
      let url = Constants.url.viewIndividualDetail + "?slug=" + slug;
      if (this.redirectFromComingSoon == true) {
        url = Constants.url.ComingSoonEpisodeDetailBySlug + "?slug=" + slug;
      }
      this.apiService.getApiData(url).subscribe((response) => {
        if (response["status"] == 200) {
          if (!!response["data"]["episodeData"]) {
            let resdata = (this.storedData = response["data"]["episodeData"]);

            if (this.redirectFromComingSoon == true) {
              resdata["english"]["_id"] = 0;
              resdata["hindi"]["_id"] = 0;
            }

            this.s3Url = response["data"]["s3Url"];
            this.allGenreList.selectedData = resdata["english"]["subGenreList"];
            this.allGenreList.showGenre = true;

            // store id and slug detail
            this.storeDetail.english._id = resdata["english"]["_id"];
            this.storeDetail.hindi._id = resdata["hindi"]["_id"];
            this.storeDetail.slug = resdata["english"]["slug"];

            // set up video
            this.selecteVideoElement.sourceLink =
              resdata["english"]["sourceLink"];
            this.selecteVideoElement.hlsSourceLink =
              resdata["english"]["hlsSourceLink"];
            this.selecteVideoElement.duration = resdata["english"]["duration"];
            let t = resdata["english"]["subtitle"];
            if (t != undefined) {
              this.selecteVideoElement.subtitle = {
                hin: !!t.hin ? t.hin : "",
                en: !!t.en ? t.en : "",
              };
            }
            this.selecteVideoElement.clips =
              resdata["english"]["clips"] != undefined
                ? resdata["english"]["clips"]
                : { video: "", waterMarkStatus: false, duration: 0 };

            resdata["english"]["gradient"] =
              !!resdata["english"]["gradient"] == true
                ? resdata["english"]["gradient"]
                : "#ffffff";
            resdata["hindi"]["gradient"] =
              !!resdata["hindi"]["gradient"] == true
                ? resdata["hindi"]["gradient"]
                : "#ffffff";

            resdata["english"]["gradients"] =
              !!resdata["english"]["gradients"] == true
                ? resdata["english"]["gradients"]
                : "#ffffff";
            resdata["hindi"]["gradients"] =
              !!resdata["hindi"]["gradients"] == true
                ? resdata["hindi"]["gradients"]
                : "#ffffff";
            // //console.log(resdata[this.selectedLang]['gradients']);
            // //console.log(resdata['hindi']['gradients']);

            this.imageGradientColorEng =
              !!resdata["english"]["gradients"] == true
                ? resdata["english"]["gradients"]
                : [];
            this.imageGradientColorHin =
              !!resdata["hindi"]["gradients"] == true
                ? resdata["hindi"]["gradients"]
                : [];

            this.setValueIntoForm("english", resdata);
            this.setValueIntoForm("hindi", resdata);
            this.setValueOfCategoryList(
              "english",
              resdata["english"]["categoryList"]
            );

            this.setValueOfComplaineCategoryList(
              "english",
              resdata["english"]["complianceList"]
            );

            this.setImagesIntoForm("english", resdata);
            this.setLabelValueIntoForm("english", resdata);
            this.setcomplianceRatingValueIntoForm("english", resdata);
            this.setAllthumbImages(
              "english",
              resdata["english"]["allThumbnails"] != undefined
                ? resdata["english"]["allThumbnails"]
                : []
            );
            this.setAllthumbImages(
              "hindi",
              resdata["hindi"]["allThumbnails"] != undefined
                ? resdata["hindi"]["allThumbnails"]
                : []
            );

            if (resdata["english"]["defaultThumbnailIndex"]) {
              this.defaultThumbnailIndex["english"] =
                resdata["english"]["defaultThumbnailIndex"];
            } else {
              this.defaultThumbnailIndex["english"] = 0;
            }

            if (resdata["hindi"]["defaultThumbnailIndex"]) {
              this.defaultThumbnailIndex["hindi"] =
                resdata["hindi"]["defaultThumbnailIndex"];
            } else {
              this.defaultThumbnailIndex["hindi"] = 0;
            }

            // //console.log(this.defaultThumbnailIndex);

            if (
              resdata["english"]["defaultImage"] ||
              resdata["hindi"]["defaultImage"]
            ) {
              this.defaultImage["english"] = resdata["english"]["defaultImage"];
              this.defaultImage["hindi"] = resdata["hindi"]["defaultImage"];

              if (this.defaultImage["english"] == true) {
                if (this.defaultThumbnailIndex["english"] == 0) {
                  this.firstThumbnailObjCheckBox["english"] = true;
                } else {
                  this.otherImages["english"][
                    this.defaultThumbnailIndex["english"] - 1
                  ]["defaultImageStatus"] = true;
                }
              }

              if (this.defaultImage["hindi"] == true) {
                if (this.defaultThumbnailIndex["hindi"] == 0) {
                  this.firstThumbnailObjCheckBox["hindi"] = true;
                } else {
                  this.otherImages["hindi"][
                    this.defaultThumbnailIndex["hindi"] - 1
                  ]["defaultImageStatus"] = true;
                }
              }
            } else {
              this.defaultImage["english"] = false;
              this.defaultImage["hindi"] = false;
            }
            this.validateForm();
          }
        }
        this.apidataLoaded = false;
      });
    } else {
      this.allGenreList.showGenre = true;
    }
  }

  setLabelValueIntoForm(lang, resdata) {
    if (!!resdata[lang]["label"]) {
      this.labels.forEach((element) => {
        if (element[lang] == resdata[lang]["label"]) {
          this.tForm.patchValue({
            [lang]: {
              label: element,
            },
          });
        }
      });
      this.copyDataFromOneLangToOther("label");
    }
  }

  setcomplianceRatingValueIntoForm(lang, resdata) {
    if (!!resdata[lang]["complianceRating"]) {
      this.ratings.forEach((element) => {
        if (element[lang] == resdata[lang]["complianceRating"]) {
          this.tForm.patchValue({
            [lang]: {
              complianceRating: element,
            },
          });
        }
      });
      this.copyDataFromOneLangToOther("complianceRating");
    }
  }

  changeSelectedLang(lang) {
    if (this.selectedLang != lang) {
      // this.tForm.reset();
      let prevLang = this.selectedLang;
      this.selectedLang = lang;
      // this.imageGradientColor = [];
      // this.imageGradientColor = !!this.storedData[lang]['gradients'] == true ? this.storedData[lang]['gradients'] : []

      this.setValueIntoForm(lang, this.storedData);
      this.setValueIntoForm(prevLang, this.storedData);

      // this.setValueOfCategoryList(lang, this.storedData[prevLang]['categoryList']);

      this.setImagesIntoForm(lang, this.storedData);

      this.validateForm();
    }
  }

  // form validate function
  validateForm() {
    if (
      this.tForm.get("english").valid &&
      this.validateImages &&
      !!this.selecteVideoElement.sourceLink
    )
      this.formStatus["english"] = true;
    else this.formStatus["english"] = false;

    if (
      this.tForm.get("hindi").valid &&
      this.validateImages &&
      !!this.selecteVideoElement.sourceLink
    )
      this.formStatus["hindi"] = true;
    else this.formStatus["hindi"] = false;
  }

  // validate  iamge status
  get validateImages() {
    // if (this.selectedImages.horizontal_large.status != 'success' || this.selectedImages.square.status != 'success' || this.selectedImages.vertical.status != 'success' || this.selectedImages.horizontal_small.status != 'success')
    if (
      this.selectedImages.horizontal_large.status != "success" ||
      this.selectedImages.square.status != "success" ||
      this.selectedImages.tv_image.status != "success" ||
      this.selectedImages.horizontal7_2.status != "success" ||
      this.selectedImages.vertical.status != "success"
    )
      return false;
    return true;
  }

  //  Save data as draft for both add/edit
  saveApiCall(lang, status) {
    //console.log('save api');

    this.validateForm();

    this.saveApiCalling = true;

    if (this.tForm.get(lang + ".title").invalid) {
      this.tForm.get(lang + ".title").markAllAsTouched();
      this.toastr.error("Please enter a valid title.");
      this.saveApiCalling = false;
    } else if (this.tForm.get(lang + ".slug").invalid) {
      this.tForm.get(lang + ".slug").markAllAsTouched();
      this.toastr.error("Title already exist");
      this.saveApiCalling = false;
    }
    // api call to save as draft
    else {
      this.saveApiCalling = true;
      if (
        (!this.formStatus[lang] ||
          this.allGenreList.formData["dSubGenreList"][lang].length == 0) &&
        status != "draft"
      ) {
        this.markTouched[lang] = true;
        this.validateAllFormFields(this.tForm, lang);
        this.saveApiCalling = false;
        this.toastr.error("Please enter all the mandatory fields.");
        return false;
      }

      let tempArr: any = {};
      tempArr["english"] = this.createRequestArray("english", status);
      tempArr["english"] = this.addFields("english", tempArr["english"]);
     
      tempArr["hindi"] = this.createRequestArray("hindi", status);
      tempArr["hindi"] = this.addFields("hindi", tempArr["hindi"]);
      tempArr["episodes"] = [];

      tempArr["english"]["gradients"] = this.imageGradientColorEng;
      tempArr["hindi"]["gradients"] = this.imageGradientColorHin;

      this.apiService
        .postData(Constants.url.updateIndividualDetail, tempArr)
        .subscribe((response) => {
          if (response["status"] == 200) {
            this.storeDetail.english._id = response["data"]["episodeId"]["en"];
            this.storeDetail.hindi._id = response["data"]["episodeId"]["hin"];

            this.storedData = tempArr;
            window.scrollTo(0, 0);
            $(".tab-conteContent").animate({ scrollTop: "position.top" }, 2000);
            if (status == "completed" && this.selectedLang == "english") {
              this.changeSelectedLang("hindi");
              this.toastr.success("Detail saved successfully.");
            } else if (status == "completed" && this.selectedLang == "hindi") {
              this.toastr.success(
                "Detail saved successfully and send to completed."
              );
              if (this.formType == "Create")
                this.router.navigate(["../listing/individual"], {
                  queryParams: { type: "completed" },
                  relativeTo: this.route,
                });
              else
                this.router.navigate(["../../listing/individual"], {
                  queryParams: { type: "completed" },
                  relativeTo: this.route,
                });
            } else if (status == "publish") {
              this.toastr.success("Send to publish successfully.");
              this.router.navigate(["../../listing/review"], {
                queryParams: { type: "individual" },
                relativeTo: this.route,
              });
            } else if (status == "active") {
              this.toastr.success("Send to live successfully.");
              this.router.navigate(["../../../review-publish/listing/active"], {
                queryParams: { type: "individual" },
                relativeTo: this.route,
              });
            } else {
              this.toastr.success("Saved as draft successfully.");
            }
          } else {
            this.toastr.error(response["message"]);
          }
          this.saveApiCalling = false;
        });
    }
  }

  addFields(lang, data){
    if(this.tForm.get(lang +".introStartTime").value != undefined){
      data.introStartTime = this.tForm.get(lang +".introStartTime").value;
      data.introEndTime = this.tForm.get(lang +".introEndTime").value;
    }

    if(this.tForm.get(lang +".nextEpisodeNudgeStartTime").value != undefined){
      data.nextEpisodeNudgeStartTime = this.tForm.get(lang +".nextEpisodeNudgeStartTime").value;
      //data.nextEpisodeNudgeEndTime = this.tForm.get(lang +".nextEpisodeNudgeEndTime").value;
    }

    if(this.tForm.get(lang +".contentWarnings").value != undefined){
      data.contentWarnings = this.tForm.get(lang +".contentWarnings").value;
      // let cwmess = [];
      // element.contentWarnings.forEach((ele, index12) => {
      //   let obj = {"startTime": 0, "endTime":0, "id": 0,"type": "Select", "message": ""};
      //   if(lang == this.selectedLang){
      //     obj.endTime = ele.endTime;
      //     obj.startTime = ele.startTime;
      //     obj.id = ele.id;
      //     obj.type = ele.type;
      //     obj.message = ele.en_message;
      //   }else{
      //     obj.endTime = ele.endTime;
      //     obj.startTime = ele.startTime;
      //     obj.id = ele.id;
      //     obj.type = ele.type;
      //     obj.message = ele.hin_message;
      //   }
      //   cwmess.push(obj)
      // });
      //reqData[i].contentWarnings = cwmess;
    }

    if(this.tForm.get(lang +".adsTime").value != undefined){
      data.adsTime = this.tForm.get(lang +".adsTime").value;
    }

    return data;
  }

  // function for mark field as validate -------------------
  validateAllFormFields(formGroup: FormGroup, lang: string) {
    Object.keys(formGroup.controls[lang]["controls"]).forEach((field) => {
      const control = formGroup.controls[lang].get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control, lang);
      }
    });
  }

  // set image into form
  setImagesIntoForm(lang, resdata) {
    this.tForm.patchValue({
      [lang]: {
        horizontal_large: "",
        square: "",
        vertical: "",
        // 'horizontal_small': ''
        tv_image: "",
        horizontal7_2: "",
      },
    });

    this.selectedImages = {
      horizontal_large: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"] ==
          true
            ? this.s3Url["basePath"] +
              "/episode" +
              Constants.image.horizontalMedium +
              resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"] ==
          true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"] ==
          true
            ? resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"]
            : null,
        widthRatio: 16,
        heightRatio: 9,
      },
      square: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"] == true
            ? this.s3Url["basePath"] +
              "/episode" +
              Constants.image.squareMedium +
              resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"] == true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"] == true
            ? resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"]
            : null,
        widthRatio: 1,
        heightRatio: 1,
      },
      // "vertical": {
      //   file: null,
      //   progPer: null,
      //   loader: false,
      //   imgURL: !!resdata[lang]['thumbnail']['vertical']['ratio1']['sourceLink'] == true ? this.s3Url['basePath'] + '/episode' + Constants.image.verticalMedium + resdata[lang]['thumbnail']['vertical']['ratio1']['sourceLink'] : null,
      //   status: !!resdata[lang]['thumbnail']['vertical']['ratio1']['sourceLink'] == true ? 'success' : 'empty',
      //   sourceLink: !!resdata[lang]['thumbnail']['vertical']['ratio1']['sourceLink'] == true ? resdata[lang]['thumbnail']['vertical']['ratio1']['sourceLink'] : null,
      //   widthRatio: 9,
      //   heightRatio: 16
      // },
      // "horizontal_small": {
      //   file: null,
      //   progPer: null,
      //   loader: false,
      //   imgURL: !!resdata[lang]['thumbnail']['horizontal']['ratio3']['sourceLink'] == true ? this.s3Url['basePath'] + '/episode' + Constants.image.horizontalSmall + resdata[lang]['thumbnail']['horizontal']['ratio3']['sourceLink'] : null,
      //   status: !!resdata[lang]['thumbnail']['horizontal']['ratio3']['sourceLink'] == true ? 'success' : 'empty',
      //   sourceLink: !!resdata[lang]['thumbnail']['horizontal']['ratio3']['sourceLink'] == true ? resdata[lang]['thumbnail']['horizontal']['ratio3']['sourceLink'] : null,
      //   widthRatio: 3,
      //   heightRatio: 2
      // }
      tv_image: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"] ==
          true
            ? this.s3Url["basePath"] +
              "/episode" +
              Constants.image.horizontalMedium +
              resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"] ==
          true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"] ==
          true
            ? resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"]
            : null,
        widthRatio: 1754,
        heightRatio: 353,
      },
      horizontal7_2: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!(
            resdata[lang]["thumbnail"]["horizontal"]["ratio4"] &&
            resdata[lang]["thumbnail"]["horizontal"]["ratio4"]["sourceLink"]
          ) == true
            ? this.s3Url["basePath"] +
              "/episode" +
              Constants.image.horizontalMedium +
              resdata[lang]["thumbnail"]["horizontal"]["ratio4"]["sourceLink"]
            : null,
        status:
          !!(
            resdata[lang]["thumbnail"]["horizontal"]["ratio4"] &&
            resdata[lang]["thumbnail"]["horizontal"]["ratio4"]["sourceLink"]
          ) == true
            ? "success"
            : "empty",
        sourceLink:
          !!(
            resdata[lang]["thumbnail"]["horizontal"]["ratio4"] &&
            resdata[lang]["thumbnail"]["horizontal"]["ratio4"]["sourceLink"]
          ) == true
            ? resdata[lang]["thumbnail"]["horizontal"]["ratio4"]["sourceLink"]
            : null,
        widthRatio: 7,
        heightRatio: 2,
      },
      vertical: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"] ==
          true
            ? this.s3Url["basePath"] +
              "/episode" +
              Constants.image.verticalMedium +
              resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"] ==
          true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"] ==
          true
            ? resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"]
            : null,
        widthRatio: 2,
        heightRatio: 3,
      },
    };
  }

  // set patch values from old array or response data into form
  setValueIntoForm(lang, arrData) {
    //console.log('patch');

    if (arrData[lang]["artistList"].length > 0) {
      arrData[lang]["artistList"].map((item) => {
        return (item["display"] = item["name"]);
      });
    }

    this.tForm.patchValue({
      [lang]: {
        title: arrData[lang]["title"],
        slug: arrData[lang]["slug"],
        description: arrData[lang]["description"],
        tags:
          !!arrData[lang]["tags"] == true
            ? arrData[lang]["tags"].split(",")
            : [],
        gradient: arrData[lang]["gradient"],
        metaTitle: arrData[lang]["metaTitle"],
        language: arrData[lang]["language"],
        metaKeyword:
          !!arrData[lang]["metaKeyword"] == true
            ? arrData[lang]["metaKeyword"].split(",")
            : [],
        metaDescription: arrData[lang]["metaDescription"],
        preContentWarningText: arrData[lang]["preContentWarningText"],
        artistList: arrData[lang]["artistList"],
        randomOrder: arrData[lang]["randomOrder"],
        freeEpisode: arrData[lang]["freeEpisode"],
        freeEpisodeDuration: arrData[lang]["freeEpisodeDuration"],
        
      

        isIntro         : arrData[lang].introStartTime != undefined && arrData[lang].introStartTime != 0 ? true: false,
        isNextEpisode   : arrData[lang].nextEpisodeNudgeStartTime != undefined && arrData[lang].nextEpisodeNudgeStartTime != 0 ? true: false,
        isWarningMess   : arrData[lang].contentWarnings != undefined && arrData[lang].contentWarnings.length > 0 ? true: false,
        isAds           : arrData[lang].adsTime != undefined && arrData[lang].adsTime.length > 0  ? true: false,
        introStartTime  : arrData[lang].introStartTime != undefined ? arrData[lang].introStartTime : 0,
        introEndTime    : arrData[lang].introEndTime != undefined ? arrData[lang].introEndTime : 0,
        nextEpisodeNudgeStartTime  : arrData[lang].nextEpisodeNudgeStartTime != undefined ? arrData[lang].nextEpisodeNudgeStartTime : 0,
        //  : arrData[lang].nextEpisodeNudgeEndTime != undefined ? arrData[lang].nextEpisodeNudgeEndTime : 0,
        contentWarnings  : arrData[lang].contentWarnings != undefined && arrData[lang].contentWarnings.length > 0  ? arrData[lang].contentWarnings : [],
        adsTime           : arrData[lang].adsTime != undefined && arrData[lang].adsTime.length > 0 ? arrData[lang].adsTime : [],
      },
    });
  }

  setValueOfCategoryList(lang, list) {
    let formArray = this.tForm.get(lang + ".categoryList") as FormArray;
    list.forEach((element) => {
      let index = formArray.value.findIndex((x) => x.id == element.id);
      if (index > -1) {
        formArray.at(index).patchValue({
          checked: true,
          slug: formArray.value[index].slug,
          id: formArray.value[index].id,
          name: formArray.value[index].name,
        });
      }
    });

    this.copyCategoryDataToAnother("categoryList");
  }

  setValueOfComplaineCategoryList(lang, list) {
    let formArray = this.tForm.get(lang + ".complianceList") as FormArray;
    if(list != undefined && list.length > 0){
      list.forEach((element) => {
        let index = formArray.value.findIndex((x) => x.id == element.id);
        if (index > -1) {
          formArray.at(index).patchValue({
            checked: true,
            slug: formArray.value[index].slug,
            id: formArray.value[index].id,
            name: formArray.value[index].name,
          });
        }
      });
      this.copyComplienceCategoryDataToAnother("complianceList");
    }
  }

  //creating a form array and
  createRequestArray(lang, status) {
    //console.log('createRequestArray---------');

    //console.log(this.tForm.get(lang + '.freeEpisode').value);
    //console.log(this.tForm.get(lang + '.freeEpisodeDuration').value);
    // return
    var hinValidate = false;
    if (status == "completed" && this.selectedLang == "hindi") {
      hinValidate = true;
    } else if (
      status == "forReview" ||
      status == "publish" ||
      status == "active"
    ) {
      hinValidate = true;
    }

    return {
      _id: this.storeDetail[lang]._id,
      title: this.tForm.get(lang + ".title").value,
      label: this.tForm.get(lang + ".label").value[lang],
      complianceRating: this.tForm.get(lang + ".complianceRating").value[lang],
      description: this.tForm.get(lang + ".description").value,
      status:
        (status == "completed" &&
          this.storedData[lang]["status"] == "completed") ||
        (status == "completed" && this.selectedLang == "hindi")
          ? "completed"
          : status == "publish" || status == "forReview" || status == "active"
          ? status
          : "draft",
      language: this.tForm.get(lang + ".language").value,
      tags: this.tForm.get(lang + ".tags").value.join(","),
      slug: this.storeDetail.slug,
      thumbnail: {
        horizontal: {
          ratio1: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.horizontal_large.sourceLink
                : this.storedData[lang]["thumbnail"]["horizontal"]["ratio1"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
          // "ratio3": {
          //   "sourceLink": lang == this.selectedLang ? this.selectedImages.horizontal_small.sourceLink : this.storedData[lang]['thumbnail']['horizontal']['ratio3']['sourceLink'],
          //   "gradient": this.tForm.get(lang + '.gradient').value
          // },
          ratio3: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.tv_image.sourceLink
                : this.storedData[lang]["thumbnail"]["horizontal"]["ratio3"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
          ratio4: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.horizontal7_2.sourceLink
                : this.storedData[lang]["thumbnail"]["horizontal"]["ratio4"]
                ? this.storedData[lang]["thumbnail"]["horizontal"]["ratio4"][
                    "sourceLink"
                  ]
                : "",
            gradient: this.tForm.get(lang + ".gradient").value,
          },
          ratio2: {
            sourceLink: "",
            gradient: "",
          },
        },
        // "vertical": {
        //   "ratio1": {
        //     "sourceLink": lang == this.selectedLang ? this.selectedImages.vertical.sourceLink : this.storedData[lang]['thumbnail']['vertical']['ratio1']['sourceLink'],
        //     "gradient": this.tForm.get(lang + '.gradient').value
        //   }
        // },
        square: {
          ratio1: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.square.sourceLink
                : this.storedData[lang]["thumbnail"]["square"]["ratio1"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
        },
        vertical: {
          ratio1: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.vertical.sourceLink
                : this.storedData[lang]["thumbnail"]["vertical"]["ratio1"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
        },
      },
      allThumbnails: this.getImageArr(lang),
      defaultImage: this.defaultImage[lang],
      defaultThumbnailIndex: this.defaultThumbnailIndex[lang],
      sourceLink: this.selecteVideoElement["sourceLink"],
      hlsSourceLink: this.selecteVideoElement["hlsSourceLink"],
      duration: this.selecteVideoElement["duration"],
      genreList: this.allGenreList.formData["dGenreList"][lang],
      subGenreList: this.allGenreList.formData["dSubGenreList"][lang],
      artistList: this.tForm.get(lang + ".artistList").value,
      categoryList: this.createRequestCategoryArr(lang),
      complianceList: this.createRequestComplianceCategoryArr(lang),
      gradient: this.tForm.get(lang + ".gradient").value,
      gradients:
        lang == this.selectedLang
          ? this.imageGradientColorEng
          : this.imageGradientColorHin,
      mediaList: [],
      selectedPeripheral: {},
      mediaCount: 0,
      episodeCount: 0,
      metaTitle: this.tForm.get(lang + ".metaTitle").value,
      metaKeyword: this.tForm.get(lang + ".metaKeyword").value.join(","),
      metaDescription: this.tForm.get(lang + ".metaDescription").value,
      preContentWarningText: this.tForm.get(lang + ".preContentWarningText").value,
      // "activity": {
      //   "action": this.formType == 'add' ? 'created' : 'updated',
      //   "writerName": "Maverick",
      //   "updatedAt": "" //from backend
      // },
      activity: {
        action: !this.storedData[lang]["slug"] == true ? "created" : "updated",
        writerName: this.userData.firstName,
        roleId: this.userData.roleId,
        updatedAt: "", //from backend
      },
      displayLanguage: lang == "english" ? "en" : "hin",
      englishValidated:
        status == "completed" || status == "forReview" || status == "active"
          ? true
          : false,
      hindiValidated: hinValidate,
      randomOrder: !!this.tForm.get(lang + ".randomOrder").value
        ? this.tForm.get(lang + ".randomOrder").value
        : 0,
      clips: this.selecteVideoElement["clips"],
      subtitle: this.selecteVideoElement["subtitle"],
      freeEpisode: this.tForm.get(lang + ".freeEpisode").value,
      freeEpisodeDuration: this.tForm.get(lang + ".freeEpisodeDuration").value,
      // freeEpisode : new FormControl(element['freeEpisode']),
      // freeEpisodeDuration : new FormControl(element['freeEpisodeDuration'])
    };
  }

  // create requested data of category list on basis of checked flag status in array.
  createRequestCategoryArr(lang) {
    let reqData = [];
    this.tForm.get(lang + ".categoryList").value.forEach((element) => {
      if (element.checked == true) {
        reqData.push({
          id: element.id,
          name: element.name,
        });
      }
    });

    return reqData;
  }

  createRequestComplianceCategoryArr(lang) {
    let reqData = [];
    this.tForm.get(lang + ".complianceList").value.forEach((element) => {
      if (element.checked == true) {
        reqData.push({
          id: element.id,
          name: element.name,
        });
      }
    });

    return reqData;
  }

  sendToCorrection(status, type) {
    this.saveApiCalling = true;
    this.apiService
      .getApiData(
        Constants.url.sendToCorrection +
          "?type=" +
          type +
          "&status=" +
          status +
          "&slug=" +
          this.storeDetail.slug
      )
      .subscribe((response) => {
        if (response["status"] == 200) {
          if (status == "reviewed")
            this.toastr.success("Send to review successfully.");
          else if (status == "draft") {
            this.toastr.success("Send for correction successfully.");
            this.router.navigate(["../../listing/review"], {
              queryParams: { type: "individual" },
              relativeTo: this.route,
            });
          }
        } else {
          this.toastr.error(response["message"]);
        }
        this.saveApiCalling = false;
      });
  }

  showConfirmationPopUp(type, text) {
    this.discardObj.type = type;
    this.discardObj.text = text;
    this.discardObj.isShown = true;
  }

  returnFromComponent(obj) {
    if (obj.isFunctionCalled) {
      this.sendToCorrection("draft", "episode");
    }
    this.discardObj.isShown = false;
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  addMoreImages() {
    let enobj = JSON.parse(JSON.stringify(this.otherImageObj));
    let hinobj = JSON.parse(JSON.stringify(this.otherImageObj));
    this.otherImages["english"].push(enobj);
    this.otherImages["hindi"].push(hinobj);
    // //console.log(this.otherImages[this.selectedLang]);
  }

  changeOtherImage(event, objName, index, currLang) {
    if (event.target.files.length === 0) return;

    let file = event.target.files[0];

    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      var self = this;
      this.checkImageDimension(
        _event,
        self,
        this.otherImages[currLang][index][objName].widthRatio,
        this.otherImages[currLang][index][objName].heightRatio,
        function (res) {
          if (res) {
            self.otherImages[currLang][index][objName].imgURL = reader.result;
            self.otherImages[currLang][index][objName].loader = true;
            self.otherImages[currLang][index][objName].status = "uploading";
            let imageFileName =
              "episodeImage-" + Date.now() + "." + file.name.split(".")[1];
            self.uploadService
              .uploadSingleFile(file, imageFileName, "episode", objName)
              .then(
                (response) => {
                  if (response) {
                    // API call for uploading/change image
                    self.apiService
                      .getApiData(
                        Constants.url.uploadImage +
                          "?type=episode&format=" +
                          objName +
                          "&imageName=" +
                          imageFileName
                      )
                      .subscribe((response) => {
                        if (response["status"] == 200) {
                          self.otherImages[currLang][index][objName].fileName =
                            imageFileName;
                          self.otherImages[currLang][index][
                            objName
                          ].sourceLink = imageFileName;
                          self.otherImages[currLang][index][objName].status =
                            "success";
                          self.otherImages[currLang][index]["lang"] = currLang;

                          self.otherImages[currLang][index].color = response[
                            "data"
                          ]["gradientColor"].length
                            ? response["data"]["gradientColor"][0]
                            : "";
                          // //console.log(self.otherImages);

                          if (
                            self.otherImages[currLang][index][objName]
                              .widthRatio === 1 &&
                            self.otherImages[currLang][index][objName]
                              .heightRatio === 1 &&
                            self.selectedLang == "english"
                          ) {
                            self.imageGradientColorEng = [];
                            self.imageGradientColorEng =
                              response["data"]["gradientColor"];

                            if (self.imageGradientColorEng.length > 0) {
                              self.setOtherImageColor(
                                self.imageGradientColorEng[0],
                                index
                              );
                            }
                          }

                          if (
                            self.otherImages[currLang][index][objName]
                              .widthRatio === 1 &&
                            self.otherImages[currLang][index][objName]
                              .heightRatio === 1 &&
                            self.selectedLang == "hindi"
                          ) {
                            self.imageGradientColorHin = [];
                            self.imageGradientColorHin =
                              response["data"]["gradientColor"];

                            if (self.imageGradientColorHin.length > 0) {
                              self.setOtherImageColor(
                                self.imageGradientColorHin[0],
                                index
                              );
                            }
                          }
                          self.otherImages[currLang][index][objName].loader =
                            false;
                          self.validateForm();
                        } else
                          self.otherImages[currLang][index][objName].status =
                            "failed";
                      });
                  } else {
                    self.otherImages[currLang][index][objName].status =
                      "failed";
                  }
                  self.validateForm();
                },
                (error) => {
                  self.otherImages[currLang][index][objName].status = "failed";
                }
              );
          } else {
            alert(
              "Image aspect ratio should be " +
                self.otherImages[currLang][index][objName].widthRatio +
                ":" +
                self.otherImages[currLang][index][objName].heightRatio
            );
          }
          self.validateForm();
        }
      );
    };
  }

  setOtherImageColor(color, i) {
    if (this.selectedLang == "english") {
      this.otherImages[this.selectedLang][i].english.gradient = color;
    } else {
      this.otherImages[this.selectedLang][i].hindi.gradient = color;
    }
  }

  getImageArr(lang) {
    let dbthumbArr = [];
    if (this.otherImages[lang].length) {
      let imageArr = this.otherImages[lang];
      //console.log(imageArr);
      imageArr.forEach((element) => {
        let obj = JSON.parse(JSON.stringify(this.storedData.english.thumbnail));
        delete obj.activity;
        obj.horizontal.ratio1.sourceLink =
          element.horizontal_large.sourceLink != null
            ? element.horizontal_large.sourceLink
            : "";
        // obj.horizontal.ratio3.sourceLink = element.horizontal_small.sourceLink != null ? element.horizontal_small.sourceLink : '';
        obj.square.ratio1.sourceLink =
          element.square.sourceLink != null ? element.square.sourceLink : "";
        obj.vertical.ratio1.sourceLink =
          element.vertical.sourceLink != null
            ? element.vertical.sourceLink
            : "";
        // obj.vertical.ratio1.sourceLink = element.vertical.sourceLink != null ? element.vertical.sourceLink : '';
        obj.horizontal.ratio3.sourceLink =
          element.tv_image.sourceLink != null
            ? element.tv_image.sourceLink
            : "";
        obj.horizontal.ratio4.sourceLink =
          element.horizontal7_2.sourceLink != null
            ? element.horizontal7_2.sourceLink
            : "";
        if (lang == "english") {
          obj.horizontal.ratio1.gradient = element.english.gradient;
          // obj.horizontal.ratio3.gradient = element.english.gradient;
          obj.square.ratio1.gradient = element.english.gradient;
          // obj.vertical.ratio1.gradient = element.english.gradient;
        } else {
          obj.horizontal.ratio1.gradient = element.hindi.gradient;
          // obj.horizontal.ratio3.gradient = element.hindi.gradient;
          obj.square.ratio1.gradient = element.hindi.gradient;
          // obj.vertical.ratio1.gradient = element.hindi.gradient;
        }
        dbthumbArr.push(obj);
      });
      return dbthumbArr;
    } else {
      return dbthumbArr;
    }
  }
  setAllthumbImages(lang, allThumbnailsArr) {
    // //console.log('allThumbnailsArr');
    // //console.log(allThumbnailsArr);
    if (allThumbnailsArr.length) {
      allThumbnailsArr.forEach((element) => {
        let imgObj = JSON.parse(JSON.stringify(this.otherImageObj));
        imgObj.horizontal_large.imgURL =
          !!element.horizontal.ratio1.sourceLink == true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.horizontalMedium +
              element.horizontal.ratio1.sourceLink
            : null;
        imgObj.horizontal_large.sourceLink =
          !!element.horizontal.ratio1.sourceLink == true
            ? element.horizontal.ratio1.sourceLink
            : null;
        imgObj.horizontal_large.status =
          !!element.horizontal.ratio1.sourceLink == true ? "success" : "empty";

        // imgObj.horizontal_small.imgURL = !!element.horizontal.ratio3.sourceLink == true ? this.s3Url['basePath'] + this.s3Url['episodePath'] + Constants.image.horizontalSmall + element.horizontal.ratio3.sourceLink : null;
        // imgObj.horizontal_small.sourceLink = !!element.horizontal.ratio3.sourceLink == true ? element.horizontal.ratio3.sourceLink : null;
        // imgObj.horizontal_small.status = !!element.horizontal.ratio3.sourceLink == true ? 'success' : 'empty';
        imgObj.tv_image.imgURL =
          !!element.horizontal.ratio3.sourceLink == true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.horizontalMedium +
              element.horizontal.ratio3.sourceLink
            : null;
        imgObj.tv_image.sourceLink =
          !!element.horizontal.ratio3.sourceLink == true
            ? element.horizontal.ratio3.sourceLink
            : null;
        imgObj.tv_image.status =
          !!element.horizontal.ratio3.sourceLink == true ? "success" : "empty";

        imgObj.horizontal7_2.imgURL =
          !!(
            element.horizontal.ratio4 && element.horizontal.ratio4.sourceLink
          ) == true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.horizontalMedium +
              element.horizontal.ratio4.sourceLink
            : null;
        imgObj.horizontal7_2.sourceLink =
          !!(
            element.horizontal.ratio4 && element.horizontal.ratio4.sourceLink
          ) == true
            ? element.horizontal.ratio4.sourceLink
            : null;
        imgObj.horizontal7_2.status =
          !!(
            element.horizontal.ratio4 && element.horizontal.ratio4.sourceLink
          ) == true
            ? "success"
            : "empty";

        imgObj.vertical.imgURL =
          !!element.vertical.ratio3.sourceLink == true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.verticalMedium +
              element.vertical.ratio3.sourceLink
            : null;
        imgObj.vertical.sourceLink =
          !!element.vertical.ratio3.sourceLink == true
            ? element.vertical.ratio3.sourceLink
            : null;
        imgObj.vertical.status =
          !!element.vertical.ratio3.sourceLink == true ? "success" : "empty";
        imgObj.square.imgURL =
          !!element.square.ratio1.sourceLink == true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.squareMedium +
              element.square.ratio1.sourceLink
            : null;
        imgObj.square.sourceLink =
          !!element.square.ratio1.sourceLink == true
            ? element.square.ratio1.sourceLink
            : null;
        imgObj.square.status =
          !!element.square.ratio1.sourceLink == true ? "success" : "empty";
        imgObj.color =
          !!element.square.ratio1.gradient == true
            ? element.square.ratio1.gradient
            : null;

        // imgObj.vertical.imgURL = !!element.vertical.ratio1.sourceLink == true ? this.s3Url['basePath'] + this.s3Url['episodePath'] + Constants.image.verticalMedium + element.vertical.ratio1.sourceLink : null;
        // imgObj.vertical.sourceLink = !!element.vertical.ratio1.sourceLink == true ? element.vertical.ratio1.sourceLink : null;
        // imgObj.vertical.status = !!element.vertical.ratio1.sourceLink == true ? 'success' : 'empty';

        imgObj.horizontal_large[lang] = element.horizontal.ratio1.gradient;
        this.otherImages[lang].push(imgObj);
      });
      // //console.log(this.otherImages[lang]);
    }
  }

  firstThumbnailObjCheckBox = { english: false, hindi: false };
  defaultImage = { english: false, hindi: false };
  defaultThumbnailIndex = { english: 0, hindi: 0 };

  thumbOperation(imgObjType, lang, index, checkBoxStatus) {
    // //console.log(lang);
    // //console.log(checkBoxStatus);
    // //console.log(index);

    /* Firstly unset all checkbox to false */
    if (this.otherImages[lang]) {
      this.otherImages[lang].forEach((element) => {
        element.defaultImageStatus = false;
      });
    }
    this.firstThumbnailObjCheckBox[lang] = false;

    if (imgObjType == "firstImgObj") {
      this.defaultThumbnailIndex[lang] = 0;
      /* SET for checked checbox */
      this.firstThumbnailObjCheckBox[lang] = checkBoxStatus;
    } else {
      this.defaultThumbnailIndex[lang] = index + 1;
      /* SET for checked checbox */
      this.otherImages[lang][index]["defaultImageStatus"] = checkBoxStatus;
    }
    this.defaultImage[lang] = checkBoxStatus;
  }

  multipleThIn: any = "";
  deleteMulImages(index) {
    this.multipleThIn = index;
  }

  popUpYesBtn() {
    this.otherImages["english"].splice(this.multipleThIn, 1);
    this.otherImages["hindi"].splice(this.multipleThIn, 1);
  }

  addWaringMess(index, type){
    if(type == 'adsTime'){
      this.tForm.get(this.selectedLang + "." + type).value.push({"startTime": 0, "endTime":0});
    }else{
      this.tForm.get(this.selectedLang + "." + type).value.push({"startTime": 0, "endTime":0, "id": 0,"type": "Select", "message": ""});
    }
  }

  insertTime(type, i, j, timeType, value){
    this.tForm.get(this.selectedLang + "."+type).value[j][timeType] = parseInt(value);
  }

  selectWarningType(type, i, j, timeType, value){
    if(value > 0){
      this.tForm.get(this.selectedLang+ "."+type).value[j]['message'] = this.warningType[value].enText;
      //this.tForm.get('hindi'+ "."+type).value[j]['message'] = this.warningType[value].hinText;

      this.tForm.get(this.selectedLang+ "."+type).value[j]['id'] = value;
      //this.tForm.get('hindi'+ "."+type).value[j]['id'] = value;

      this.tForm.get(this.selectedLang+ "."+type).value[j]['type'] = this.warningType[value].type;
      //this.tForm.get('hindi'+ "."+type).value[j]['type'] = this.warningType[value].type;
    }else{
      this.tForm.get(this.selectedLang+ "."+type).value[j]['message'] = "";
      //this.tForm.get('hindi'+ "."+type).value[j]['message'] = "";

      this.tForm.get(this.selectedLang+ "."+type).value[j]['id'] = 0;
      //this.tForm.get('hindi'+ "."+type).value[j]['id'] = 0;

      this.tForm.get(this.selectedLang+ "."+type).value[j]['type'] = "";
      //this.tForm.get('hindi'+ "."+type).value[j]['type'] = "";
    }
  }
}
