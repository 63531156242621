import { Component, OnInit } from "@angular/core";
import { AuthService } from "../login/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  roleId = 0;
  userData = {
    _id: "",
    email: "",
    roleId: 0,
    firstName: "",
    lastName: "",
    lastLogin: "",
    showSubscriptionTab: false,
  };
  showNotificationTab = true;
  globalLanguage = !!this.getCookie("GlobalDialect")
    ? this.getCookie("GlobalDialect")
    : "har";

  isHomeTab = false;
  isYoutubeVideoTab = false;
  showSubscriptionTab = false;
  isExperimentTab = false;
  isInvoice = false;
  ngOnInit() {
    var domainName = window.location.hostname;

    if (domainName == "cms.stage.in") {
      this.showNotificationTab = false;
    }

    if (this.route["_routerState"].snapshot.url.match("home/")) {
      this.isHomeTab = true;
    }

    if (this.route["_routerState"].snapshot.url.match("experiment-list")) {
      this.isExperimentTab = true;
    }

    if (this.route["_routerState"].snapshot.url.match("youtubevideo")) {
      this.isYoutubeVideoTab = true;
    }

    if (this.route["_routerState"].snapshot.url.match("invoice-detail")) {
      this.isInvoice = true;
    }

    var retrievedObject = localStorage.getItem("userdata");
    this.userData = JSON.parse(retrievedObject);
    this.roleId = this.userData.roleId;
    // //console.log("this userdata");
    // //console.log(this.userData);
    // //console.log(this.roleId)
    if (
      this.userData.showSubscriptionTab != undefined &&
      this.userData.showSubscriptionTab
    ) {
      this.showSubscriptionTab = true;
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  changeDialect() {
    this.eraseCookie("GlobalDialect");
    this.setCookie("GlobalDialect", this.globalLanguage, 100);
    window.location.reload();
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }

  TabCheck(tab) {
    if (tab == "home" || tab == "notification-tab") {
      this.isHomeTab = true;
      this.isExperimentTab = false;
      this.isYoutubeVideoTab = false;
      this.isInvoice = false;
    } else if (tab == "experiment-list") {
      this.isExperimentTab = true;
      this.isHomeTab = false;
      this.isYoutubeVideoTab = false;
      this.isInvoice = false;
    } else if (tab == "youtubevideo") {
      this.isYoutubeVideoTab = true;
      this.isHomeTab = false;
      this.isExperimentTab = false;
      this.isInvoice = false;
    } else if (tab == "invoice") {
      this.isInvoice = true;
      this.isHomeTab = false;
      this.isExperimentTab = false;
      this.isYoutubeVideoTab = false;
    }else {
      this.isHomeTab = false;
      this.isExperimentTab = false;
      this.isYoutubeVideoTab = false;
      this.isInvoice = false;
      // this.eraseCookie('GlobalDialect')
      // this.setCookie('GlobalDialect','har',100);
    }
  }

  navigateToNewPortal() {
    const newPortalURL = environment.newPortalURL || "http://localhost:3000";
    window.open(newPortalURL, "_blank");
  }
}
