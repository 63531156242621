import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { ApiService } from "src/app/other/services/api.service";
import { Constants } from "src/app/other/constants";
import { UploadFileService } from "src/app/other/services/upload-file.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-individual-coming-soon-detail",
  templateUrl: "./individual-coming-soon-detail.component.html",
  styleUrls: ["./individual-coming-soon-detail.component.scss"],
})
export class IndividualComingSoonDetailComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private uploadService: UploadFileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    uploadService.uploadStatus.subscribe((value) => {
      if (!!value["loaded"]) {
        this.selectedImages[value["objName"]].progPer =
          (value["loaded"] / value["total"]) * 100;
      }
    });
  }

  tForm: FormGroup;
  selectedLang: string = "english";
  currentUrl = "";
  labels = [
    {
      english: "new",
      hindi: "नया",
    },
    {
      english: "viral",
      hindi: "वायरल",
    },
    {
      english: "trending",
      hindi: "चर्चा में",
    },
  ];
  saveApiCalling: boolean = false;

  videoList = {
    all: {
      collectionVideo: [],
      peripheralVideo: [],
    },
    selected: {
      collectionVideo: [],
      peripheralVideo: [],
    },
    s3Url: {},
  };

  s3Url = {};
  s3UrlIndividual = {};
  notApplicableStatus = false;

  individualVideoList = [];
  individualArtistList = {
    english: [],
    hindi: [],
  };
  imageGradientColorEng = [];
  imageGradientColorHin = [];
  storedData = {
    english: {
      thumbnail: {
        horizontal: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
          ratio2: {
            sourceLink: null,
            gradient: null,
          },
          ratio3: {
            sourceLink: "",
            gradient: "",
          },
        },
        vertical: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
        square: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
      },
      activity: {
        action: "created",
        writerName: "Kunal",
        updatedAt: "",
      },
    },
    hindi: {
      thumbnail: {
        horizontal: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
          ratio2: {
            sourceLink: null,
            gradient: null,
          },
          ratio3: {
            sourceLink: "",
            gradient: "",
          },
        },
        vertical: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
        square: {
          ratio1: {
            sourceLink: null,
            gradient: null,
          },
        },
      },
      activity: {
        action: "created",
        writerName: "Maverick",
        updatedAt: "",
      },
    },
  };

  formType: string = "Create";
  formStatus = {
    english: false,
    hindi: false,
  };

  storeDetail = {
    slug: null,
    english: {
      _id: 0,
    },
    hindi: {
      _id: 0,
    },
  };

  selecteVideoElement = {
    title: null,
    sourceLink: null,
    hlsSourceLink: null,
    duration: null,
    thumbnail: null,
    id: null,
  };

  allGenreList = {
    showGenre: false,
    allListData: [],
    selectedData: [],
    formData: {},
  };

  discardObj = {
    type: "",
    text: "",
    isShown: false,
  };

  categoryList = [];

  selectedImages = {
    horizontal_large: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 16,
      heightRatio: 9,
    },
    square: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 1,
      heightRatio: 1,
    },
    vertical: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 9,
      heightRatio: 16,
    },
    horizontal_small: {
      file: null,
      progPer: null,
      loader: false,
      imgURL: null,
      status: "empty",
      sourceLink: null,
      widthRatio: 3,
      heightRatio: 2,
    },
  };

  markTouched = {
    english: false,
    hindi: false,
  };

  userData = {
    _id: "",
    email: "",
    roleId: 0,
    firstName: "",
    lastName: "",
    lastLogin: "",
  };
  apidataLoaded = false;
  viewMode = false;
  isLived = false;
  displayMedia = "poster";
  expandToFullVersion = false;

  ngOnInit() {
    this.tForm = new FormGroup({
      english: new FormGroup({
        title: new FormControl("", [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ]),
        slug: new FormControl("", [Validators.required]),
        description: new FormControl("", [
          Validators.required,
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        language: new FormControl(""),
        artistList: new FormControl([]),
        label: new FormControl(""),
        categoryList: new FormArray([]),
        tags: new FormControl([]),
        gradient: new FormControl("#fffff"),
        // 'gradient': new FormControl('#ffffff'),
        metaTitle: new FormControl(""),
        metaKeyword: new FormControl([]),
        metaDescription: new FormControl(""),
        horizontal_large: new FormControl(""),
        square: new FormControl(""),
        vertical: new FormControl(""),
        horizontal_small: new FormControl(""),
        gradients: new FormControl(""),
        randomOrder: new FormControl(0),
      }),
      hindi: new FormGroup({
        title: new FormControl("", [
          Validators.required,
          Validators.maxLength(50),
          this.noWhitespaceValidator,
        ]),
        slug: new FormControl("", [Validators.required]),
        description: new FormControl("", [
          Validators.required,
          Validators.maxLength(500),
          this.noWhitespaceValidator,
        ]),
        language: new FormControl(""),
        artistList: new FormControl([]),
        label: new FormControl(""),
        categoryList: new FormArray([]),
        tags: new FormControl([]),
        gradient: new FormControl("#fffff"),
        // 'gradient': new FormControl('#ffffff'),
        metaTitle: new FormControl(""),
        metaKeyword: new FormControl([]),
        metaDescription: new FormControl(""),
        horizontal_large: new FormControl(""),
        square: new FormControl(""),
        vertical: new FormControl(""),
        horizontal_small: new FormControl(""),
        gradients: new FormControl(""),
        randomOrder: new FormControl(0),
      }),
    });

    // subscribtion for form validation
    this.tForm.valueChanges.subscribe((result) => {
      this.validateForm();
    });

    this.route.params.subscribe((params) => {
      if (!!params["slug"]) {
        this.formType = "edit";
        this.storeDetail.slug = params["slug"];
      }
    });

    // API call for category and genre sub-genre listing
    this.getCategoryList();
    this.getGenreList();
    this.getIndividualVideoList();
    this.getArtistList();

    var retrievedObject = localStorage.getItem("userdata");
    this.userData = JSON.parse(retrievedObject);

    if (
      this.route["_routerState"].snapshot.url.match("dashboard/review-publish")
    ) {
      this.currentUrl = "review-publish";
    } else if (
      this.route["_routerState"].snapshot.url.match("dashboard/content")
    ) {
      this.currentUrl = "content";
    } else if (
      this.route["_routerState"].snapshot.url.match("dashboard/publish")
    ) {
      this.currentUrl = "publish";
    }

    if (this.route["_routerState"].snapshot.url.match("view/")) {
      this.viewMode = true;
    }
    if (
      this.route["_routerState"].snapshot.url.match("individual-comingSoon")
    ) {
      this.expandToFullVersion = true;
    }
  }

  // Custom validtor to avoid empty spaces.
  public noWhitespaceValidator(control: FormControl) {
    let isWhitespace =
      !(
        control.value === null ||
        control.value === "" ||
        control.value === undefined
      ) && control.value.trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  // copy artist language in both the arrays
  copyDataFromOneLangToOther(fieldName) {
    let value = this.tForm.get(this.selectedLang + "." + fieldName).value;

    if (this.selectedLang == "english")
      this.tForm.patchValue({
        hindi: {
          [fieldName]: value,
        },
      });
    else
      this.tForm.patchValue({
        english: {
          [fieldName]: value,
        },
      });
  }

  // copy category data from one language to another
  copyCategoryDataToAnother(fieldName) {
    let enValue = this.tForm.get("english.categoryList").value;
    let hinValue = this.tForm.get("hindi.categoryList").value;
    hinValue.forEach((element, index) => {
      if (this.selectedLang == "english")
        hinValue[index].checked = enValue[index].checked;
      else enValue[index].checked = hinValue[index].checked;
    });

    if (this.selectedLang == "english") {
      this.tForm.patchValue({
        hindi: {
          [fieldName]: hinValue,
        },
      });
    } else {
      this.tForm.patchValue({
        english: {
          [fieldName]: enValue,
        },
      });
    }

    //console.log(this.tForm.get('english.categoryList').value);
    //console.log(this.tForm.get('hindi.categoryList').value);
  }

  // change image function and api call
  changeImage(event, objName) {
    if (event.target.files.length === 0) return;

    let file = event.target.files[0];

    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      var self = this;
      this.checkImageDimension(
        _event,
        self,
        this.selectedImages[objName].widthRatio,
        this.selectedImages[objName].heightRatio,
        function (res) {
          if (res) {
            self.selectedImages[objName].imgURL = reader.result;
            self.selectedImages[objName].loader = true;
            self.selectedImages[objName].status = "uploading";
            let imageFileName =
              "episodeImage-" + Date.now() + "." + file.name.split(".")[1];
            self.uploadService
              .uploadSingleFile(file, imageFileName, "episode", objName)
              .then(
                (response) => {
                  if (response) {
                    // API call for uploading/change image
                    self.apiService
                      .getApiData(
                        Constants.url.uploadImage +
                          "?type=episode&format=" +
                          objName +
                          "&imageName=" +
                          imageFileName
                      )
                      .subscribe((response) => {
                        if (response["status"] == 200) {
                          self.selectedImages[objName].fileName = imageFileName;
                          self.selectedImages[objName].sourceLink =
                            imageFileName;
                          self.selectedImages[objName].status = "success";
                          if (
                            self.selectedImages[objName].widthRatio === 1 &&
                            self.selectedImages[objName].heightRatio === 1 &&
                            self.selectedLang == "english"
                          ) {
                            self.imageGradientColorEng = [];
                            self.imageGradientColorEng =
                              response["data"]["gradientColor"];

                            if (self.imageGradientColorEng.length > 0) {
                              self.setImageColor(self.imageGradientColorEng[0]);
                            }
                          }

                          if (
                            self.selectedImages[objName].widthRatio === 1 &&
                            self.selectedImages[objName].heightRatio === 1 &&
                            self.selectedLang == "hindi"
                          ) {
                            self.imageGradientColorHin = [];
                            self.imageGradientColorHin =
                              response["data"]["gradientColor"];

                            if (self.imageGradientColorHin.length > 0) {
                              self.setImageColor(self.imageGradientColorHin[0]);
                            }
                          }
                          self.selectedImages[objName].loader = false;
                          self.validateForm();
                        } else self.selectedImages[objName].status = "failed";
                      });
                  } else {
                    self.selectedImages[objName].status = "failed";
                  }
                  self.validateForm();
                },
                (error) => {
                  self.selectedImages[objName].status = "failed";
                }
              );
          } else {
            alert(
              "Image aspect ratio should be " +
                self.selectedImages[objName].widthRatio +
                ":" +
                self.selectedImages[objName].heightRatio
            );
          }
          self.validateForm();
        }
      );
    };
  }

  checkImageDimension(e, classObj, widthRatio, heightRatio, callback) {
    var isValid = false;
    var image = new Image();
    image.src = e.target["result"];
    let self = this;
    image.onload = function (): any {
      if ((image.width * heightRatio) / widthRatio === image.height) {
        isValid = true;
        callback(isValid);
      } else {
        callback(isValid);
      }
    };
  }

  checkSlugApi() {
    let reqSlug = "";
    if (!this.storeDetail.english._id) {
      if (this.tForm.get("english.title").valid) {
        reqSlug = this.convertToSlug(this.tForm.get("english.title").value);
        this.apiService
          .getApiData(Constants.url.checkSlug + "?type=episode&slug=" + reqSlug)
          .subscribe((response) => {
            if (response["status"] == 200) {
              if (!response["data"]["exists"]) {
                this.storeDetail.slug = reqSlug;

                this.tForm.patchValue({
                  english: {
                    slug: reqSlug,
                  },
                });
                this.tForm.patchValue({
                  hindi: {
                    slug: reqSlug,
                  },
                });
              } else {
                this.toastr.info("Slug already exists.Please try different");
                this.storeDetail.slug = "";

                this.tForm.patchValue({
                  english: {
                    slug: "",
                  },
                });
                this.tForm.patchValue({
                  hindi: {
                    slug: "",
                  },
                });
              }

              this.tForm.get("english.slug").markAllAsTouched();
              this.tForm.get("hindi.slug").markAllAsTouched();
            }
          });
      }
    }
  }

  // slug convertor
  convertToSlug(text) {
    return text.toLowerCase().replace(/\s\s+/g, " ").replace(/ +/g, "-");
  }

  setImageColor(color) {
    if (this.selectedLang == "english") {
      this.tForm.patchValue({
        english: {
          ["gradient"]: color,
        },
      });
    } else {
      this.tForm.patchValue({
        hindi: {
          ["gradient"]: color,
        },
      });
    }
  }

  // get genre list
  getGenreList() {
    this.apiService
      .getApiData(Constants.url.getGenreSubGenreList)
      .subscribe((response) => {
        if (response["status"] == 200) {
          this.allGenreList.allListData = response["data"]["genreSubGenre"];
          // call if slug is available
          this.viewIndividualDetail(this.storeDetail.slug);
        }
      });
  }

  // public requestAutocompleteItems = (text: string): Observable<Response> => {
  //   return this.apiService.getApiData(Constants.url.getIndividualArtistListing).pipe(map(res => res['data']['artistData']));
  // }

  // get Artist Video list
  getArtistList() {
    this.apiService
      .getApiData(Constants.url.getIndividualArtistListing)
      .subscribe((response) => {
        if (response["status"] == 200) {
          // this.individualArtistList=response['data']['artistData'];
          response["data"]["artistData"].map((item) => {
            if (item.displayLanguage == "en") {
              return this.individualArtistList["english"].push({
                id: item._id,
                firstName: item.firstName,
                lastName: item.lastName,
                callingName: item.callingName,
                name: item.firstName + " " + item.lastName,
                order: 0,
                city: item.city,
                profilePic: item.profilePic,
                slug: item.slug,
                status: item.status,
                gender: item.gender,
              });
            } else {
              return this.individualArtistList["hindi"].push({
                id: item._id,
                firstName: item.firstName,
                lastName: item.lastName,
                callingName: item.callingName,
                name: item.firstName + " " + item.lastName,
                order: 0,
                city: item.city,
                profilePic: item.profilePic,
                slug: item.slug,
                status: item.status,
                gender: item.gender,
              });
            }
          });
        }
      });
  }

  // add artist to hindi lang
  addArtistToOther(tag: any) {
    let artistHinIndex = this.individualArtistList["hindi"].findIndex(
      (x) => x.slug == tag.slug
    );
    if (artistHinIndex > -1) {
      let value = this.tForm.get("hindi.artistList").value;
      value.push(this.individualArtistList["hindi"][artistHinIndex]);

      this.tForm.patchValue({
        hindi: {
          artistList: value,
        },
      });
    }
  }

  // remove artist to other lang
  removeArtistToOther(tag: any) {
    let otherLang = this.selectedLang == "english" ? "hindi" : "english";

    let value = this.tForm.get(otherLang + ".artistList").value;
    let valueIndex = value.findIndex((x) => x.slug == tag.slug);
    value.splice(valueIndex, 1);

    this.tForm.patchValue({
      [otherLang]: {
        artistList: value,
      },
    });
  }

  // remove artist to other lang
  // removeArtistToOther(tag:any, formIndex){
  //   let otherLang = this.selectedLang == 'english'?'hindi':'english';

  //   let value = this.detailFields('hindi')['controls'][formIndex]['controls']['artistList'].value;
  //   let valueIndex = value.findIndex(x => x.slug == tag.slug);
  //   value.splice(valueIndex, 1);

  //   this.detailFields(otherLang)['controls'][formIndex].patchValue({
  //     'artistList': value
  //   });
  // }

  // get Artist Video list
  getIndividualVideoList() {
    if (this.individualVideoList.length <= 0) {
      this.apiService
        .getApiData(Constants.url.getIndividualVideoList)
        .subscribe((response) => {
          if (response["status"] == 200) {
            this.individualVideoList = response["data"]["episodeVideo"];
            this.s3UrlIndividual = response["data"]["s3Url"];
            this.s3Url = response["data"]["s3Url"];
          }
        });
    }
  }

  // bind/unbind the artis Story
  bindUnbindArtistStory(item) {
    if (!item) {
      this.selecteVideoElement = {
        title: null,
        sourceLink: null,
        hlsSourceLink: null,
        duration: null,
        thumbnail: null,
        id: null,
      };
      // this.storedData.english.mediaList = [];
      // this.storedData.hindi.mediaList  = [];
    } else {
      item.id = Math.floor(Math.random() * 100) + 1;
      this.selecteVideoElement = item;
      // this.storedData.english.mediaList.push(item);
      // this.storedData.hindi.mediaList.push(item);
    }

    this.validateForm();
  }

  // get category list
  getCategoryList() {
    this.apiService
      .getApiData(Constants.url.getCategoryList)
      .subscribe((response) => {
        if (response["status"] == 200) {
          let newData = [];
          let categorySlugs = [];
          for (let element of response["data"]["categories"]) {
            var indexVal = categorySlugs.indexOf(element.slug);
            if (indexVal == -1) {
              let obj = {};
              obj["slug"] = element.slug;
              if (element.language === "en") {
                obj["english"] = { id: element._id, name: element.name };
              } else {
                obj["hindi"] = { id: element._id, name: element.name };
              }
              newData.push(obj);
              categorySlugs.push(element.slug);
            } else {
              if (element.language === "en") {
                newData[indexVal]["english"] = {
                  id: element._id,
                  name: element.name,
                };
              } else {
                newData[indexVal]["hindi"] = {
                  id: element._id,
                  name: element.name,
                };
              }
            }
          }

          newData.forEach((o, i) => {
            let formArray = this.tForm.get(
              "english" + ".categoryList"
            ) as FormArray;
            let hinFormArray = this.tForm.get(
              "hindi" + ".categoryList"
            ) as FormArray;
            formArray.push(
              this.fb.group({
                checked: new FormControl(null),
                slug: o.slug,
                id: o["english"].id,
                name: o["english"].name,
              })
            );
            hinFormArray.push(
              this.fb.group({
                checked: new FormControl(null),
                slug: o.slug,
                id: o["hindi"].id,
                name: o["hindi"].name,
              })
            );
          });
          this.categoryList = newData;
        }
      });
  }

  // view collection detail
  viewIndividualDetail(slug) {
    if (!!slug) {
      this.apidataLoaded = true;
      let url = Constants.url.ComingSoonEpisodeDetailBySlug + "?slug=" + slug;
      this.apiService.getApiData(url).subscribe((response) => {
        if (response["status"] == 200) {
          if (!!response["data"]["episodeData"]) {
            let resdata = (this.storedData = response["data"]["episodeData"]);
            this.s3Url = response["data"]["s3Url"];
            this.allGenreList.selectedData = resdata["english"]["subGenreList"];
            this.allGenreList.showGenre = true;
            this.notApplicableStatus = resdata["english"]["notApplicable"];
            // store id and slug detail
            this.storeDetail.english._id = resdata["english"]["_id"];
            this.storeDetail.hindi._id = resdata["hindi"]["_id"];
            this.storeDetail.slug = resdata["english"]["slug"];

            if (resdata["isLived"] != undefined) {
              this.isLived = resdata["isLived"];
            } else {
              this.isLived = false;
            }

            // set up video
            this.selecteVideoElement.sourceLink =
              resdata["english"]["sourceLink"];
            this.selecteVideoElement.hlsSourceLink =
              resdata["english"]["hlsSourceLink"];
            this.selecteVideoElement.duration = resdata["english"]["duration"];

            resdata["english"]["gradient"] =
              !!resdata["english"]["gradient"] == true
                ? resdata["english"]["gradient"]
                : "#ffffff";
            resdata["hindi"]["gradient"] =
              !!resdata["hindi"]["gradient"] == true
                ? resdata["hindi"]["gradient"]
                : "#ffffff";

            resdata["english"]["gradients"] =
              !!resdata["english"]["gradients"] == true
                ? resdata["english"]["gradients"]
                : "#ffffff";
            resdata["hindi"]["gradients"] =
              !!resdata["hindi"]["gradients"] == true
                ? resdata["hindi"]["gradients"]
                : "#ffffff";
            // //console.log(resdata['english']['gradients']);
            // //console.log(resdata['hindi']['gradients']);

            this.imageGradientColorEng =
              !!resdata["english"]["gradients"] == true
                ? resdata["english"]["gradients"]
                : [];
            this.imageGradientColorHin =
              !!resdata["hindi"]["gradients"] == true
                ? resdata["hindi"]["gradients"]
                : [];

            this.setValueIntoForm("english", resdata);
            this.setValueIntoForm("hindi", resdata);
            this.setValueOfCategoryList(
              "english",
              resdata["english"]["categoryList"]
            );
            this.setImagesIntoForm("english", resdata);
            this.setLabelValueIntoForm("english", resdata);

            this.validateForm();
          }
        }
        this.apidataLoaded = false;
      });
    } else {
      this.allGenreList.showGenre = true;
    }
  }

  setLabelValueIntoForm(lang, resdata) {
    if (!!resdata[lang]["label"]) {
      this.labels.forEach((element) => {
        if (element[lang] == resdata[lang]["label"]) {
          this.tForm.patchValue({
            [lang]: {
              label: element,
            },
          });
        }
      });
      this.copyDataFromOneLangToOther("label");
    }
  }

  changeSelectedLang(lang) {
    if (this.selectedLang != lang) {
      // this.tForm.reset();
      let prevLang = this.selectedLang;
      this.selectedLang = lang;
      // this.imageGradientColor = [];
      // this.imageGradientColor = !!this.storedData[lang]['gradients'] == true ? this.storedData[lang]['gradients'] : []

      this.setValueIntoForm(lang, this.storedData);
      this.setValueIntoForm(prevLang, this.storedData);

      // this.setValueOfCategoryList(lang, this.storedData[prevLang]['categoryList']);

      this.setImagesIntoForm(lang, this.storedData);

      this.validateForm();
    }
  }

  // form validate function
  validateForm() {
    if (this.tForm.get("english").valid) this.formStatus["english"] = true;
    else this.formStatus["english"] = false;

    if (this.tForm.get("hindi").valid) this.formStatus["hindi"] = true;
    else this.formStatus["hindi"] = false;
  }

  // validate  iamge status
  get validateImages() {
    if (
      this.selectedImages.horizontal_large.status != "success" ||
      this.selectedImages.square.status != "success" ||
      this.selectedImages.vertical.status != "success" ||
      this.selectedImages.horizontal_small.status != "success"
    )
      return false;
    return true;
  }

  //  Save data as draft for both add/edit
  saveApiCall(lang, status) {
    this.validateForm();

    this.saveApiCalling = true;

    if (this.tForm.get(lang + ".title").invalid) {
      this.tForm.get(lang + ".title").markAllAsTouched();
      this.toastr.error("Please enter a valid title.");
      this.saveApiCalling = false;
    } else if (this.tForm.get(lang + ".slug").invalid) {
      this.tForm.get(lang + ".slug").markAllAsTouched();
      this.toastr.error("Title already exist");
      this.saveApiCalling = false;
    }
    // api call to save as draft
    else {
      this.saveApiCalling = true;
      if (!this.formStatus[lang] && status != "draft") {
        this.markTouched[lang] = true;
        this.validateAllFormFields(this.tForm, lang);
        this.saveApiCalling = false;
        this.toastr.error("Please enter all the mandatory fields.");
        return false;
      }

      let tempArr: any = {};
      tempArr["english"] = this.createRequestArray("english", status);
      tempArr["hindi"] = this.createRequestArray("hindi", status);
      tempArr["episodes"] = [];

      tempArr["english"]["gradients"] = this.imageGradientColorEng;
      tempArr["hindi"]["gradients"] = this.imageGradientColorHin;

      if (status != "draft") {
        if (
          !tempArr[this.selectedLang].thumbnail.horizontal.ratio1.sourceLink
        ) {
          this.saveApiCalling = false;
          this.toastr.error("Please Upload Thumbnail..");
          return false;
        }

        if (
          this.notApplicableStatus == true &&
          tempArr["english"].artistList.length == 0
        ) {
          this.saveApiCalling = false;
          this.toastr.error("Please select Artist....");
          return false;
        } else if (
          this.notApplicableStatus == false &&
          tempArr["english"].sourceLink == ""
        ) {
        }
      }
      if (tempArr["english"].mediaList.length > 0) {
        tempArr["english"].displayMedia =
          tempArr["hindi"].displayMedia =
          this.displayMedia =
            "media";
      } else {
        tempArr["english"].displayMedia = tempArr["hindi"].displayMedia =
          "poster";
      }
      /* //console.log(tempArr);
      return; */
      this.apiService
        .postData(Constants.url.saveComingSoonIndividualEpisode, tempArr)
        .subscribe((response) => {
          if (response["status"] == 200) {
            this.storeDetail.english._id = response["data"]["episodeId"]["en"];
            this.storeDetail.hindi._id = response["data"]["episodeId"]["hin"];

            this.storedData = tempArr;
            window.scrollTo(0, 0);
            $(".tab-conteContent").animate({ scrollTop: "position.top" }, 2000);
            if (status == "completed" && this.selectedLang == "english") {
              this.changeSelectedLang("hindi");
              this.toastr.success("Detail saved successfully.");
            } else if (status == "completed" && this.selectedLang == "hindi") {
              this.toastr.success(
                "Detail saved successfully and send to completed."
              );
              if (this.formType == "Create")
                this.router.navigate(["../listing/individual"], {
                  queryParams: { type: "completed" },
                  relativeTo: this.route,
                });
              else
                this.router.navigate(["../../listing/individual"], {
                  queryParams: { type: "completed" },
                  relativeTo: this.route,
                });
            } else if (status == "publish") {
              this.toastr.success("Send to publish successfully.");
              this.router.navigate(["../../listing/review"], {
                queryParams: { type: "individual" },
                relativeTo: this.route,
              });
            } else if (status == "active") {
              this.toastr.success("Send to live successfully.");
              this.router.navigate(["../../../review-publish/listing/active"], {
                queryParams: { type: "individual" },
                relativeTo: this.route,
              });
            } else {
              this.toastr.success("Saved as draft successfully.");
            }
          } else {
            this.toastr.error(response["message"]);
          }
          this.saveApiCalling = false;
        });
    }
  }

  // function for mark field as validate -------------------
  validateAllFormFields(formGroup: FormGroup, lang: string) {
    Object.keys(formGroup.controls[lang]["controls"]).forEach((field) => {
      const control = formGroup.controls[lang].get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control, lang);
      }
    });
  }

  // set image into form
  setImagesIntoForm(lang, resdata) {
    this.tForm.patchValue({
      [lang]: {
        horizontal_large: "",
        square: "",
        vertical: "",
        horizontal_small: "",
      },
    });

    this.selectedImages = {
      horizontal_large: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"] ==
          true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.horizontalMedium +
              resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"] ==
          true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"] ==
          true
            ? resdata[lang]["thumbnail"]["horizontal"]["ratio1"]["sourceLink"]
            : null,
        widthRatio: 16,
        heightRatio: 9,
      },
      square: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"] == true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.squareMedium +
              resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"] == true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"] == true
            ? resdata[lang]["thumbnail"]["square"]["ratio1"]["sourceLink"]
            : null,
        widthRatio: 1,
        heightRatio: 1,
      },
      vertical: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"] ==
          true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.verticalMedium +
              resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"] ==
          true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"] ==
          true
            ? resdata[lang]["thumbnail"]["vertical"]["ratio1"]["sourceLink"]
            : null,
        widthRatio: 9,
        heightRatio: 16,
      },
      horizontal_small: {
        file: null,
        progPer: null,
        loader: false,
        imgURL:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"] ==
          true
            ? this.s3Url["basePath"] +
              this.s3Url["episodePath"] +
              Constants.image.horizontalSmall +
              resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"]
            : null,
        status:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"] ==
          true
            ? "success"
            : "empty",
        sourceLink:
          !!resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"] ==
          true
            ? resdata[lang]["thumbnail"]["horizontal"]["ratio3"]["sourceLink"]
            : null,
        widthRatio: 3,
        heightRatio: 2,
      },
    };
  }

  // set patch values from old array or response data into form
  setValueIntoForm(lang, arrData) {
    if (arrData[lang]["artistList"].length > 0) {
      arrData[lang]["artistList"].map((item) => {
        return (item["display"] = item["name"]);
      });
    }

    this.tForm.patchValue({
      [lang]: {
        title: arrData[lang]["title"],
        slug: arrData[lang]["slug"],
        description: arrData[lang]["description"],
        tags:
          !!arrData[lang]["tags"] == true
            ? arrData[lang]["tags"].split(",")
            : [],
        gradient: arrData[lang]["gradient"],
        metaTitle: arrData[lang]["metaTitle"],
        language: arrData[lang]["language"],
        metaKeyword:
          !!arrData[lang]["metaKeyword"] == true
            ? arrData[lang]["metaKeyword"].split(",")
            : [],
        metaDescription: arrData[lang]["metaDescription"],
        artistList: arrData[lang]["artistList"],
        randomOrder: arrData[lang]["randomOrder"],
      },
    });
  }

  setValueOfCategoryList(lang, list) {
    let formArray = this.tForm.get(lang + ".categoryList") as FormArray;
    list.forEach((element) => {
      let index = formArray.value.findIndex((x) => x.id == element.id);
      if (index > -1) {
        formArray.at(index).patchValue({
          checked: true,
          slug: formArray.value[index].slug,
          id: formArray.value[index].id,
          name: formArray.value[index].name,
        });
      }
    });

    this.copyCategoryDataToAnother("categoryList");
  }

  //creating a form array and
  createRequestArray(lang, status) {
    var hinValidate = false;
    if (status == "completed" && this.selectedLang == "hindi") {
      hinValidate = true;
    } else if (
      status == "forReview" ||
      status == "publish" ||
      status == "active"
    ) {
      hinValidate = true;
    }

    return {
      _id: this.storeDetail[lang]._id,
      contentState: "comingSoon",
      notApplicable: this.notApplicableStatus,
      isLived: this.isLived,
      displayMedia: this.displayMedia,
      title: this.tForm.get(lang + ".title").value,
      label: this.tForm.get(lang + ".label").value[lang],
      description: this.tForm.get(lang + ".description").value,
      status:
        (status == "completed" &&
          this.storedData[lang]["status"] == "completed") ||
        (status == "completed" && this.selectedLang == "hindi")
          ? "completed"
          : status == "publish" || status == "forReview" || status == "active"
          ? status
          : "draft",
      language: this.tForm.get(lang + ".language").value,
      tags: this.tForm.get(lang + ".tags").value.join(","),
      slug: this.storeDetail.slug,
      thumbnail: {
        horizontal: {
          ratio1: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.horizontal_large.sourceLink
                : this.storedData[lang]["thumbnail"]["horizontal"]["ratio1"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
          ratio3: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.horizontal_small.sourceLink
                : this.storedData[lang]["thumbnail"]["horizontal"]["ratio3"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
          ratio2: {
            sourceLink: "",
            gradient: "",
          },
        },
        vertical: {
          ratio1: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.vertical.sourceLink
                : this.storedData[lang]["thumbnail"]["vertical"]["ratio1"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
        },
        square: {
          ratio1: {
            sourceLink:
              lang == this.selectedLang
                ? this.selectedImages.square.sourceLink
                : this.storedData[lang]["thumbnail"]["square"]["ratio1"][
                    "sourceLink"
                  ],
            gradient: this.tForm.get(lang + ".gradient").value,
          },
        },
      },
      sourceLink: this.selecteVideoElement["sourceLink"],
      hlsSourceLink: this.selecteVideoElement["hlsSourceLink"],
      duration: this.selecteVideoElement["duration"],
      genreList: [],
      subGenreList: [],
      artistList: this.tForm.get(lang + ".artistList").value,
      categoryList: [],
      gradient: "",
      gradients:
        lang == this.selectedLang
          ? this.imageGradientColorEng
          : this.imageGradientColorHin,
      mediaList: [],
      selectedPeripheral: {},
      mediaCount: 0,
      episodeCount: 0,
      metaTitle: "",
      metaKeyword: "",
      metaDescription: "",
      // "activity": {
      //   "action": this.formType == 'add' ? 'created' : 'updated',
      //   "writerName": "Maverick",
      //   "updatedAt": "" //from backend
      // },
      activity: {
        action: !this.storedData[lang]["slug"] == true ? "created" : "updated",
        writerName: this.userData.firstName,
        roleId: this.userData.roleId,
        updatedAt: "", //from backend
      },
      displayLanguage: lang == "english" ? "en" : "hin",
      englishValidated:
        status == "completed" || status == "forReview" || status == "active"
          ? true
          : false,
      hindiValidated: hinValidate,
      randomOrder: !!this.tForm.get(lang + ".randomOrder").value
        ? this.tForm.get(lang + ".randomOrder").value
        : 0,
    };
  }

  // create requested data of category list on basis of checked flag status in array.
  createRequestCategoryArr(lang) {
    let reqData = [];
    this.tForm.get(lang + ".categoryList").value.forEach((element) => {
      if (element.checked == true) {
        reqData.push({
          id: element.id,
          name: element.name,
        });
      }
    });

    return reqData;
  }

  sendToCorrection(status, type) {
    this.saveApiCalling = true;
    this.apiService
      .getApiData(
        Constants.url.changeComingSoonStatus +
          "?type=" +
          type +
          "&status=" +
          status +
          "&slug=" +
          this.storeDetail.slug
      )
      .subscribe((response) => {
        if (response["status"] == 200) {
          if (status == "reviewed")
            this.toastr.success("Send to review successfully.");
          else if (status == "draft") {
            this.toastr.success("Send for correction successfully.");
            this.router.navigate(["../../listing/review"], {
              queryParams: { type: "individual" },
              relativeTo: this.route,
            });
          }
        } else {
          this.toastr.error(response["message"]);
        }
        this.saveApiCalling = false;
      });
  }

  showConfirmationPopUp(type, text) {
    this.discardObj.type = type;
    this.discardObj.text = text;
    this.discardObj.isShown = true;
  }

  returnFromComponent(obj) {
    if (obj.isFunctionCalled) {
      this.sendToCorrection("draft", "episode");
    }
    this.discardObj.isShown = false;
  }

  redirectToFullVersion() {
    if (!this.storeDetail.english._id)
      this.router.navigate(["../update-individual"], {
        relativeTo: this.route,
      });
    else
      this.router.navigate(
        [
          "../../update-individual/fromCS/" +
            this.tForm.get("english.slug").value,
        ],
        { relativeTo: this.route }
      );
  }
}
