import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getApiData(url: string) {
    //console.log(environment.baseUrl+url);

    return this.http.get(environment.baseUrl + url);
  }

  getApiDataSUB(url: string) {
    return this.http.get(url, {
      responseType: "arraybuffer",
    });
  }

  postData(url: string, data: object) {
    return this.http.post(environment.baseUrl + url, data);
  }

  postAppBackendAPI(url: string, data: object) {
    return this.http.post(environment.appBackendURL + url, data);
  }
}

// https://aws.amazon.com/blogs/developer/announcing-the-amazon-s3-managed-uploader-in-the-aws-sdk-for-javascript/
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3/ManagedUpload.html

/* import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,private router:Router, private route: ActivatedRoute) { }

  getApiData(url:string) {
    if(!this.route['_routerState'].snapshot.url.match('notification-tab')){
      return this.http.get(environment.baseUrl+url); 
    }else{
      return this.http.get('http://devcmsapi.stage.in/'+url);
    }
    
  }

  postData(url:string, data:object) {
    if(!this.route['_routerState'].snapshot.url.match('notification-tab')){
      return this.http.post(environment.baseUrl+url, data); 
    }else{
      return this.http.post('http://devcmsapi.stage.in/'+url, data); 
    }
  }
    
}


// https://aws.amazon.com/blogs/developer/announcing-the-amazon-s3-managed-uploader-in-the-aws-sdk-for-javascript/
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3/ManagedUpload.html */
