import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "slugToTite",
})
export class slugToTite implements PipeTransform {
  transform(input: any, args?: any): any {
    var re = /-/gi;
    var newstr = input.replace(re, " ");

    return newstr;
  }
}
