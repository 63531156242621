import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { ReviewPublishTabComponent } from './review-publish-tab.component';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LazyLoadImageModule, scrollPreset } from "ng-lazyload-image";
import { timeFormatter } from "../../other/pipe/time-formatter";
import { ContentDetailCardComponent } from "./content-detail-card/content-detail-card.component";
import { UpdateArtistDetailComponent } from "../update-artist-detail/update-artist-detail.component";
import { GenreSubgenreListComponent } from "../shared/genre-subgenre-list/genre-subgenre-list.component";
import { TagInputModule } from "ngx-chips";
import { UpdateCollectionDetailComponent } from "../update-collection-detail/update-collection-detail.component";
import { UpdateIndividualDetailComponent } from "../update-individual-detail/update-individual-detail.component";
import { UpdateShowDetailComponent } from "../update-show-detail/update-show-detail.component";
import { VideoBindingPopupComponent } from "./video-binding-popup/video-binding-popup.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { slugToTite } from "../../other/pipe/slug-to-title";
import { dateFormat } from "../../other/pipe/date-format";
import { DiscardButtonComponent } from "./discard-button/discard-button.component";
import { ShowComingSoonDetailComponent } from "../show-coming-soon-detail/show-coming-soon-detail.component";
import { CollectionComingSoonDetailComponent } from "../collection-coming-soon-detail/collection-coming-soon-detail.component";
import { IndividualComingSoonDetailComponent } from "../individual-coming-soon-detail/individual-coming-soon-detail.component";
import { ComingSoonContentCardComponent } from "./coming-soon-content-card/coming-soon-content-card.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";

@NgModule({
  declarations: [
    timeFormatter,
    slugToTite,
    dateFormat,
    ContentDetailCardComponent,
    UpdateArtistDetailComponent,
    GenreSubgenreListComponent,
    UpdateCollectionDetailComponent,
    UpdateIndividualDetailComponent,
    UpdateShowDetailComponent,
    VideoBindingPopupComponent,
    DiscardButtonComponent,
    ShowComingSoonDetailComponent,
    CollectionComingSoonDetailComponent,
    IndividualComingSoonDetailComponent,
    ComingSoonContentCardComponent,
  ],
  imports: [
    LazyLoadImageModule.forRoot({
      preset: scrollPreset,
    }),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    DragDropModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [
    LazyLoadImageModule,
    timeFormatter,
    dateFormat,
    slugToTite,
    ContentDetailCardComponent,
    UpdateArtistDetailComponent,
    GenreSubgenreListComponent,
    UpdateCollectionDetailComponent,
    UpdateIndividualDetailComponent,
    UpdateShowDetailComponent,
    VideoBindingPopupComponent,
    ShowComingSoonDetailComponent,
    CollectionComingSoonDetailComponent,
    IndividualComingSoonDetailComponent,
    ComingSoonContentCardComponent,
  ],
})
export class SharedModule {}
