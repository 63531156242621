import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateFormat",
})
export class dateFormat implements PipeTransform {
  transform(input: any, args?: any): any {
    var date = input.split("T");
    return date[0];
  }
}
