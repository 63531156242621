// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var domainName = window.location.hostname;
console.log(domainName);

let newPortalURL = "https://devcmsnew.stage.in/";
let appBackendURL = "https://testapi.stage.in/";

// var url = 'https://devcmsapi.stage.in/';
var url = "https://cmsapi.stage.in/";
if (domainName == "cms.stage.in") {
  url = "https://cmsapi.stage.in/";
  appBackendURL = "https://stageapi.stage.in/";
  newPortalURL = "https://newcms.stage.in/";
} else if (domainName == "devcms.stage.in") {
  url = "http://devcmsapi.stage.in/";
  appBackendURL = "httpss://testapi.stage.in/";
} else if (domainName == "localhost") {
  // url = "https://localhost:3000/"
  appBackendURL = "http://localhost:3016/";
  url = "http://localhost:3000/";
}

// console.log(domainName);
console.log(url);

export const environment = {
  production: false,
  baseUrl: url,
  newPortalURL,
  appBackendURL,
  //baseUrl: 'https://testcmsapi.stage.in/',
  //baseUrl: 'https://cmsapi.stage.in/',
  //baseUrl: 'https://devcmsapi.stage.in/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
