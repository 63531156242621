import { Component, OnInit } from "@angular/core";
import { Constants } from "src/app/other/constants";
import { ApiService } from "src/app/other/services/api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-tipping-control",
  templateUrl: "./tipping-control.component.html",
  styleUrls: ["./tipping-control.component.scss"],
})
export class TippingControlComponent implements OnInit {
  constructor(private toastr: ToastrService, private apiService: ApiService) {}

  ngOnInit() {
    this.getTipSetting();
  }

  listLanguage =
    this.getCookie("GlobalDialect") != null
      ? this.getCookie("GlobalDialect")
      : "har";
  basePath = "";
  tipingTemplateData: any = [];
  imagePaths = {};
  selectedTipImage: any = {};
  saveBtnDisable = true;

  getTipSetting() {
    let url = Constants.url.getTipSett + "?listLanguage=" + this.listLanguage;
    let req = this.apiService.getApiData(url).subscribe((response) => {
      if (response["status"] == 200) {
        this.basePath = response["data"]["s3Url"]["basePath"];
        this.imagePaths = response["data"]["s3Url"];
        this.tipingTemplateData =
          response["data"]["tipingData"]["template"]["en"];
        //console.log(this.tipingTemplateData);
        this.tipingTemplateData.forEach((element) => {
          element.checkBoxStatus = false;
          if (element.status == "active") {
            element.checkBoxStatus = true;
          }
        });
      }
    });
    // this.requestArr.push(req);
  }

  checkTipImage(index) {
    //console.log(index);

    this.tipingTemplateData.forEach((element) => {
      element.checkBoxStatus = false;
    });
    this.tipingTemplateData[index]["checkBoxStatus"] = true;
    this.selectedTipImage = this.tipingTemplateData[index];
    this.saveBtnDisable = false;
  }

  saveTippingConfig() {
    this.saveBtnDisable = true;
    this.apiService
      .postData(Constants.url.saveTippingData, { data: this.selectedTipImage })
      .subscribe((response) => {
        if (response["status"] == 200) {
          this.toastr.success("Saved Successfully");
        } else {
          this.toastr.error(response["message"]);
        }
        this.saveBtnDisable = false;
      });
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
