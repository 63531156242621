import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-discard-button",
  templateUrl: "./discard-button.component.html",
  styleUrls: ["./discard-button.component.scss"],
})
export class DiscardButtonComponent implements OnInit {
  constructor() {}

  @Input() type;
  @Input() text;
  @Input() disableEvent;
  @Output() sendEvents = new EventEmitter<{}>();

  selectObj = {
    text1: "Delete",
    text2: "Are you sure ?",
    text3: "Do you really want to close.",
    action1: "Cancel",
    action2: "Yes",
    type: "close",
    redirectLink: "../../listing/review",
    isFunctionCalled: false,
  };

  popUpCases = {
    createArtistClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to Discard.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../listing/artist",
      isFunctionCalled: false,
    },
    createShowClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to Discard.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../listing/show",
      isFunctionCalled: false,
    },
    createCollectionClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to Discard.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../listing/collection",
      isFunctionCalled: false,
    },
    createIndividualClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to Discard.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../listing/individual",
      isFunctionCalled: false,
    },
    createArtistReviewClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to close.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../../listing/review",
      isFunctionCalled: false,
    },
    createShowReviewClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to close.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../../listing/review",
      isFunctionCalled: false,
    },
    createCollectionReviewClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to close.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../../listing/review",
      isFunctionCalled: false,
    },
    createIndividualReviewClose: {
      text1: "Delete",
      text2: "Are you sure ?",
      text3: "Do you really want to close.",
      action1: "Cancel",
      action2: "Yes",
      type: "close",
      redirectLink: "../../listing/review",
      isFunctionCalled: false,
    },
    sendToCorrection: {
      text1: "Send To Corretion",
      text2: "Are you sure ?",
      text3: "Do you really want to send for correction.",
      action1: "Cancel",
      action2: "Yes",
      type: "sendToCorrection",
      apiFunction: "sendToCorrection",
      isFunctionCalled: true,
    },
  };

  ngOnInit() {
    this.selectObj = this.popUpCases[this.type];
    //console.log(this.selectObj);
    $("#discardModal").modal("show");
  }

  callApi() {
    //console.log(this.selectObj);
    this.sendEvents.emit(this.selectObj);
  }

  hideModal() {
    this.sendEvents.emit({});
  }
}
