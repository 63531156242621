import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { TippingControlComponent } from "./tipping-control/tipping-control.component";
import { AuthGuard } from "./auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  // { path: 'upload', pathMatch: './file/file-upload'},
  {
    path: "tipping-control",
    component: TippingControlComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "content", pathMatch: "full" },
      {
        path: "notification-tab",
        loadChildren:
          "./notification-tab/notification-tab.module#NotificationTabModule",
      },
      {
        path: "videos",
        loadChildren: "./videos-list/videos-list.module#VideosListModule",
      },
      {
        path: "content",
        loadChildren: "./content-tab/content-tab.module#ContentTabModule",
      },
      {
        path: "publish",
        loadChildren:
          "./review-publish-tab/review-publish-tab.module#ReviewPublishTabModule",
      },
      {
        path: "review-publish",
        loadChildren:
          "./review-publish-tab/review-publish-tab.module#ReviewPublishTabModule",
      },
      {
        path: "home",
        loadChildren: "./home-tab/home-tab.module#HomeTabModule",
      },
      {
        path: "home-user-list",
        loadChildren: "./home-tab/home-tab.module#HomeTabModule",
      },
      {
        path: "user-history",
        loadChildren: "./home-tab/home-tab.module#HomeTabModule",
      },
      {
        path: "subscription",
        loadChildren:
          "./subscription-tab/subscription-tab.module#SubscriptionTabModule",
      },
      {
        path: "support",
        loadChildren: "./support/support.module#SupportModule",
      },
      {
        path: "teleservice",
        loadChildren:
          "./teleservice-tab/teleservice-tab.module#TeleserviceTabModule",
      },
      {
        path: "youtubevideo",
        loadChildren: "./youtubevideo/youtubevideo.module#YoutubevideoModule",
      },
      {
        path: "youtube",
        loadChildren: "./youtube/youtube.module#YoutubeModule",
      },
      {
        path: "experiment-list",
        loadChildren:
          "./experiment-list/experiment-list.module#ExperimentListModule",
      },
      {
        path: "experiment",
        loadChildren: "./experiment/experiment.module#ExperimentModule",
      },
      {
        path: "invoice",
        loadChildren: "./invoice/invoice.module#InvoiceModule",
      },
      {
        path: "invoice-detail",
        loadChildren:
          "./invoice-detail/invoice-detail.module#InvoiceDetailModule",
      },
      {
        path: "deeplink",
        loadChildren: "./deeplink/deeplink.module#DeeplinkModule",
      },
      { path: "**", redirectTo: "content", pathMatch: "full" },
    ],
  },
  // { path: '**', redirectTo: 'dashboard/content', pathMatch: 'full' },
  { path: "login", loadChildren: "./login/login.module#LoginModule" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
