import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { Constants } from "src/app/other/constants";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/other/services/api.service";
import { element } from "protractor";

@Component({
  selector: "app-content-detail-card",
  templateUrl: "./content-detail-card.component.html",
  styleUrls: ["./content-detail-card.component.scss"],
})
export class ContentDetailCardComponent implements OnInit, OnChanges {
  public constantImg: any;
  changeText: boolean;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.constantImg = Constants.image;
    this.changeText = false;
  }

  @Input() type;
  @Input() s3Url;
  @Input() detail;
  @Input() reviewChecked;
  @Output() indivCheckStatus = new EventEmitter<boolean>();

  @ViewChild("selectOne", { static: false }) selectOne;

  currentUrl: string = null;
  unPublishParam = {
    status: "",
    section: "",
    slug: "",
  };

  ngOnInit() {
    if (this.route["_routerState"].snapshot.url.match("review-publish")) {
      this.currentUrl = "review-publish";
    } else if (this.route["_routerState"].snapshot.url.match("content")) {
      this.currentUrl = "content";
    }

    var names = this.detail.genreList.map(function (item) {
      return item["name"];
    });
    this.detail.genreList = names.join(", ");
  }

  ngOnChanges() {
    // this.reviewChecked = true;
  }

  checkUncheck(status) {
    if (
      (this.detail.status == "forReview" || this.detail.status == "reviewed") &&
      this.type.section == "show"
    ) {
      if (
        this.detail.mediaList.length > 1 &&
        !this.detail.selectedPeripheral["sourceLink"]
      ) {
        this.toastr.info("Please mark the select peripheral.");
        this.selectOne.nativeElement.checked = false;
        this.reviewChecked = false;
        return false;
      }
    }

    this.reviewChecked = status;
    this.indivCheckStatus.emit(status);
  }

  // redirect as per the condition
  onEdit(slug, type, status) {
    // if( (this.currentUrl =='content' && (status=='draft' || status=='completed')) || (this.currentUrl =='review-publish' && (status=='forReview' || status=='reviewed')) )
    if (true)
      if (type == "artist")
        this.router.navigate(["../../update-artist", slug], {
          relativeTo: this.route,
        });
      else if (type == "collection")
        this.router.navigate(["../../update-collection", slug], {
          relativeTo: this.route,
        });
      else if (type == "show")
        this.router.navigate(["../../update-show", slug], {
          relativeTo: this.route,
        });
      else if (type == "individual")
        this.router.navigate(["../../update-individual", slug], {
          relativeTo: this.route,
        });
  }

  onEditComingSoon(slug, type, status) {
    // if( (this.currentUrl =='content' && (status=='draft' || status=='completed')) || (this.currentUrl =='review-publish' && (status=='forReview' || status=='reviewed')) )
    if (true)
      if (type == "artist")
        this.router.navigate(["../../update-cs-artist", slug], {
          relativeTo: this.route,
        });
      else if (type == "collection")
        this.router.navigate(["../../update-cs-collection", slug], {
          relativeTo: this.route,
        });
      else if (type == "show")
        this.router.navigate(["../../update-cs-show", slug], {
          relativeTo: this.route,
        });
      else if (type == "individual")
        this.router.navigate(["../../update-cs-individual", slug], {
          relativeTo: this.route,
        });
  }

  onView(slug, type, status) {
    if (type == "artist")
      this.router.navigate(["../../update-artist/view", slug], {
        relativeTo: this.route,
      });
    else if (type == "collection")
      this.router.navigate(["../../update-collection/view", slug], {
        relativeTo: this.route,
      });
    else if (type == "show")
      this.router.navigate(["../../update-show/view", slug], {
        relativeTo: this.route,
      });
    else if (type == "individual")
      this.router.navigate(["../../update-individual/view", slug], {
        relativeTo: this.route,
      });
  }

  onEditPublished(type, slug) {
    if (type == "artist")
      this.router.navigate(["../../../publish/update-artist", slug], {
        relativeTo: this.route,
      });
    else if (type == "collection")
      this.router.navigate(["../../../publish/update-collection", slug], {
        relativeTo: this.route,
      });
    else if (type == "show")
      this.router.navigate(["../../../publish/update-show", slug], {
        relativeTo: this.route,
      });
    else if (type == "individual")
      this.router.navigate(["../../../publish/update-individual", slug], {
        relativeTo: this.route,
      });
  }

  changeStatus(status, type, slug) {
    if (type == "individual") {
      type = "episode";
    }
    this.apiService
      .getApiData(
        Constants.url.sendToCorrection +
          "?type=" +
          type +
          "&status=" +
          status +
          "&slug=" +
          slug
      )
      .subscribe((response) => {
        if (response["status"] == 200) {
          if (status === "publish") {
            this.toastr.success("Send to published successfully.");
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        }
      });
  }

  confirmDelete(status, type, slug) {
    this.apiService
      .getApiData(
        Constants.url.sendToCorrection +
          "?type=" +
          type +
          "&status=" +
          status +
          "&slug=" +
          slug
      )
      .subscribe((response) => {
        if (response["status"] == 200) {
          if (status === "publish") {
            this.toastr.success("Deleted successfully.");
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        }
      });
  }
  popUpYesBtn() {
    let status = this.getCookie("status");
    let slug = this.getCookie("slug");
    let section = this.getCookie("section");
    if (status == "deleted") {
      this.confirmDelete(status, section, slug);
    } else {
      this.changeStatus(status, section, slug);
    }
  }
  deleteCookies() {
    this.eraseCookie("status");
    this.eraseCookie("section");
    this.eraseCookie("slug");
  }

  showConfirmationPopUp(status, section, slug) {
    this.eraseCookie("status");
    this.eraseCookie("section");
    this.eraseCookie("slug");

    this.setCookie("status", status, 1);
    this.setCookie("section", section, 1);
    this.setCookie("slug", slug, 1);

    this.unPublishParam.status = status;
    this.unPublishParam.section = section;
    this.unPublishParam.slug = slug;
    // //console.log(this.unPublishParam);
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }
}
